import React from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { navActionCreators } from "../../stores/lis-nav-store";
import { sessionActionCreators } from '../../stores/lis-session-store';

const csv_info = [
    {
        csv_name: "Amendments",
    },
    {
        csv_name: "Bills",
        csv_file: "BILLS.CSV"
    },
    {
        csv_name: "CiBillSubjects",
        csv_file: "CIBillSubjects.csv"
    },
    {
        csv_name: "CiParentChildSubjects",
        csv_file: "CIParentChildSubjects.csv"
    },
    {
        csv_name: "CommitteeMembers",
    },
    {
        csv_name: "Committees",
    },
    {
        csv_name: "Docket",
        csv_file: "DOCKET.CSV"
    },
    {
        csv_name: "FiscalImpactStatements",
    },
    {
        csv_name: "History",
        csv_file: "HISTORY.CSV"
    },
    {
        csv_name: "Members",
    },
    {
        csv_name: "Section",
        csv_file: "SECTION.csv"
    },
    {
        csv_name: "Sponsors",
    },
    {
        csv_name: "SubCommitteeMembers",
    },
    {
        csv_name: "SubCommittees",
        csv_file: "SubCommittees.csv"
    },
    {
        csv_name: "Subdocket",
        csv_file: "SUBDOCKET.CSV"
    },
    {
        csv_name: "Summaries",
    },
    {
        csv_name: "Vote",
        csv_file: "VOTE.CSV"
    },
    {
        csv_name: "VoteStatements",
    }
]

class PublicDataFiles extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sessionCode: null,
            loadingSessions: true,
            sessionOptions: []
        }
    }

    componentDidMount() {
        this.props.actions.getSessionList()
            .then(() => {
                this.setState({
                    sessionOptions: this.props.session.sessionList,
                    loadingSessions: false
                }, () => {
                    if (this.state.sessionOptions && this.state.sessionOptions.length) {
                        if (this.props.match.params.sessionCode && this.state.sessionOptions.find(s => s.SessionCode === this.props.match.params.sessionCode)) {
                            const session = this.state.sessionOptions.find(s => s.SessionCode === this.props.match.params.sessionCode)
                            this.props.actions.changeSession(session.SessionCode);
                            this.setSession(this.props.match.params.sessionCode);
                        } else if (this.props.nav.session) {
                            this.setSession(this.props.nav.session)
                        } else if (this.state.sessionOptions.find(s => s.IsDefault)) {
                            this.setSession(this.state.sessionOptions.find(s => s.IsDefault).SessionCode);
                        } else {
                            //shouldn't happen, means no session was provided in the url and there is no default session set
                            this.setSession(this.state.sessionOptions[this.state.sessionOptions.length - 1].SessionCode);
                        }
                    }
                })
            }).catch(err => {
                this.setState({ loadingSessions: false })
                if (err === 'Aborted') {
                    return;
                }
                console.log(err)
            });
    }

    componentDidUpdate(prevProps) {
        if (this.props.nav.session !== prevProps.nav.session) {
            if (this.state.sessionOptions && this.state.sessionOptions.length && !isNaN(parseInt(this.props.nav.session))) {
                const session = this.state.sessionOptions.find(s => s.SessionCode === this.props.nav.session);
                if (session) {
                    if (this.props.match.params.sessionCode) {
                        //update url since it has a sessionCode in it
                        this.props.history.push('/data-files/' + session.SessionCode);
                    } else {
                        this.setSession(session.SessionCode);
                    }
                }
            }
        }
    }

    setSession(sessionCode) {
        this.setState({ sessionCode })
    }

    render() {
        const { sessionCode, loadingSessions } = this.state;

        return (
            <div className='generic-details-page public-data-files-page'>
                <div className="dlas-forms">
                    <div className="public-details">
                        <h2>CSV File Download</h2>
                    </div>
                    <div>
                        <p>Users having the desire and ability to do their own bill tracking have csv files available for download using this page. These csv files are suitable for spreadsheet or database input. Location of these files is {window.env?.STORAGE_ACCOUNT_DOMAIN}/[session_code]/[csv_filename].csv.</p>
                        <p>For questions or assistance with csv files, please contact our Help Desk at <a href="tel:8047869631">(804) 786-9631</a>, or <a href="mailto:LIS@dlas.virginia.gov">LIS@dlas.virginia.gov</a>.</p>
                    </div>
                    <div className="csv-desc">
                        <h3>BILLS.CSV</h3>
                        <p>The <strong>bills.csv</strong> file contains one row per bill. This file is updated hourly during session and reflects a 'snapshot' status of all bills. This is a comma separated values file with the following columns:</p>
                        <ol className="data-files-list list-three">
                            <li>Bill_id</li>
                            <li>Bill_description</li>
                            <li>Patron_id</li>
                            <li>Patron_name</li>
                            <li>Last_house_committee_id</li>
                            <li>Last_house_action</li>
                            <li>Last_house_action_date</li>
                            <li>Last_senate_committee_id</li>
                            <li>Last_senate_action</li>
                            <li>Last_senate_action_date</li>
                            <li>Last_conference_action</li>
                            <li>Last_conference_action_date</li>
                            <li>Last_governor_action</li>
                            <li>Last_governor_action_date</li>
                            <li>Emergency</li>
                            <li>Passed_house</li>
                            <li>Passed_senate</li>
                            <li>Passed</li>
                            <li>Failed</li>
                            <li>Carried_over</li>
                            <li>Approved</li>
                            <li>Vetoed</li>
                            <li>Full_text_doc1</li>
                            <li>Full_text_date1</li>
                            <li>Full_text_doc2</li>
                            <li>Full_text_date2</li>
                            <li>Full_text_doc3</li>
                            <li>Full_text_date3</li>
                            <li>Full_text_doc4</li>
                            <li>Full_text_date4</li>
                            <li>Full_text_doc5</li>
                            <li>Full_text_date5</li>
                            <li>Full_text_doc6</li>
                            <li>Full_text_date6</li>
                            <li>Last_house_actid</li>
                            <li>Last_senate_actid</li>
                            <li>Last_conference_act</li>
                            <li>Last_governor_actid</li>
                            <li>Chapter_id</li>
                            <li>Introduction_date</li>
                            <li>Last_actid</li>
                        </ol>
                    </div>
                    <div className="csv-desc">
                        <h3>HISTORY.CSV</h3>
                        <p>The <strong>history.csv</strong> file contains one row for each history "action" related to a given bill. It is updated once a day, overnight.
                            This is a comma separated values file with the following columns:</p>
                        <ol className="data-files-list">
                            <li>Bill_id</li>
                            <li>History_date</li>
                            <li>History_description</li>
                        </ol>
                    </div>
                    {sessionCode ?
                        <div className="csv-desc">
                            <h3>{sessionCode.substr(0, 4)} CSV FILES FOR DOWNLOAD</h3>
                            <p>Below are links to all the files that are available for download. All these files, except the history.csv (as explained above) are updated once an hour during session.</p>
                            <ul className="data-files-list list-two no-list-style">
                                {
                                    csv_info.map((csv, idx) =>
                                        <li key={idx}><a target="_blank" rel="noreferrer" href={`${window.env?.STORAGE_ACCOUNT_DOMAIN}/lisfiles/${sessionCode}/${csv.csv_file ? csv.csv_file : (csv.csv_name + '.csv')}`}>{csv.csv_name}.csv</a></li>
                                    )
                                }
                            </ul>
                        </div>
                        :
                        loadingSessions ?
                            <div className="small-spinner" />
                            :
                            <p>Error retrieving sessions.</p>
                    }
                </div>
            </div>
        )
    }
}

export default connect(
    (state) => {
        const { nav, session } = state;
        return {
            nav,
            session
        }
    },
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, navActionCreators, sessionActionCreators), dispatch)
        }
    }
)(PublicDataFiles)