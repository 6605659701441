import React from 'react';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { sessionActionCreators } from '../../../stores/lis-session-store';
import { navActionCreators } from '../../../stores/lis-nav-store';

const customStyles = {
    option: (base, state) => ({
        ...base,
        fontSize: '14px',
        fontWeight: state.data.IsActive ? 'bold' : 'normal'
    }),
    control: (base) => ({
        ...base,
        fontSize: '14px',
        background: '#f1f1f1',
        border: '1px solid #d8d8d8',
        borderRadius: '0 0 5px 5px'
    }),
    menu: (base) => ({
        ...base,
        width: '245px',
        marginTop: '1px',

    }),
    menuList: (base) => ({
        ...base,
        marginTop: '1px'
    }),
    indicatorSeparator: (base) => ({
        ...base,
        display: "none"
    }),
    singleValue: (base, state) => {
        return { ...base, };
    }
}

class SessionSelector extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            selectedSession: {},
            sessionListMessage: '',
            sessionOptions: []
        };

        this.getSessions = this.getSessions.bind(this);
    }

    componentDidMount() {
        this.getSessions();
    }

    getSessions() {
        this.props.actions.getSessionList().then(() => {
            if (this.props.session.sessionList.length > 0) {
                // FORMAT DATA FOR DROPDOWN
                let sessionList = [...this.props.session.sessionList]
                sessionList.forEach(session => {
                    session.value = session.SessionID;
                    session.label = session.SessionYear + ' ' + session.DisplayName + (session.IsActive ? ' (Active)' : '');
                    if (session.SessionCode === this.props.nav.session) {
                        this.setState({
                            selectedSession: session
                        });
                    } else if (session.IsDefault && !this.props.nav.session) {
                        this.props.actions.changeSession(session.SessionCode);
                        this.setState({
                            selectedSession: session
                        });
                    }
                });
                sessionList = sessionList.reverse();
                this.setState({
                    sessionOptions: sessionList
                });
            } else {
                this.setState({
                    sessionListMessage: this.props.session.sessionListMessage
                });
            }
        });
    }

    handleChange = value => {
        if (window.env && window.env.HISTORICAL_DATA_REDIRECT && value && !value.IsDefault && value.LegacySessionID && value.SessionCode != "20242") {
            this.setState({
                historicalSessionRedirect: (redirect) => {
                    if (redirect) {
                        let a = document.createElement('a');
                        a.target = '_blank';
                        a.href = `https://legacylis.virginia.gov/cgi-bin/legp604.exe?ses=${value.SessionCode.substring(2)}&typ=lnk&val=${value.LegacySessionID}`;
                        document.body.appendChild(a);
                        a.click();
                        document.body.removeChild(a);
                    }
                    this.setState({ historicalSessionRedirect: false })
                }
            })
            return;
        }
        if (value && value.SessionCode) {
            this.setState({
                selectedSession: this.state.sessionOptions.find(s => s.SessionCode === value.SessionCode)
            });
            this.props.actions.changeSession(value.SessionCode);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.nav.session !== this.props.nav.session || (prevState.sessionOptions.length !== this.state.sessionOptions.length && this.state.selectedSession?.SessionCode !== this.props.nav.session)) {
            const selectedSession = this.state.sessionOptions.find(option => option.SessionCode?.toString() === this.props.nav.session?.toString())
            if (selectedSession) {
                this.setState({
                    selectedSession: selectedSession
                })
            }
        }
        if (prevProps.nav.reloadSessionSelector !== this.props.nav.reloadSessionSelector && this.props.nav.reloadSessionSelector === true) {
            this.getSessions();
            this.props.actions.reloadSessionSelector(false);
        }

        if (this.props.overrideSession != "") {
            this.handleChange(this.props.overrideSession)
        }
    }

    getSelectedSessionLabel(selectedSession) {
        let newSession = { ...selectedSession } //don't modify existing session values stored at state level & used in dropdown
        if (newSession && newSession.label) {
            newSession.label = newSession.label.replace(' (Active)', '')
        }
        return newSession;
    }

    render() {
        const { sessionOptions, selectedSession } = this.state;
        const hide = this.props.nav.hideSessionSelectorPaths.find(path => new RegExp(path, 'g').test(this.props.location.pathname))

        return (
            <div className="full" id="session-select-container">
                {this.state.historicalSessionRedirect &&
                    <div className="schedule-modal">
                        <div className="schedule-modal-content fit-content center-content">
                            <p>For historical data, please use <b>Legacy LIS</b>, available at <b><a href="https://legacylis.virginia.gov" target="_blank" rel="noreferrer">https://legacylis.virginia.gov</a></b>.</p>
                            <p>Click 'Go' to be taken to Legacy LIS, or 'Close' to remain here.</p>
                            <div className="inline-list">
                                <button className="button primary float-right" onClick={() => this.state.historicalSessionRedirect(true)}>Go</button>
                                <button className="button secondary float-right" onClick={() => this.state.historicalSessionRedirect(false)}>Close</button>
                            </div>
                            <br />
                        </div>
                    </div>

                }
                <div className={hide && this.props.location.pathname !== "/profile" ? "session-select-tab hidden" : "session-select-tab"}>
                    <Select
                        id="sessionSelector"
                        classNamePrefix="global-session-selector"
                        isSearchable={false}
                        options={sessionOptions}
                        maxMenuHeight={170}
                        value={this.getSelectedSessionLabel(selectedSession)}
                        onChange={this.handleChange}
                        styles={customStyles}
                    />
                </div>
                <div className="link-wrapper">
                    <Link to="/bill-search">Advanced Search</Link>
                </div>
            </div>
        )

    }
};

export default connect(
    (state) => {
        const { session, nav } = state;
        return {
            session,
            nav
        }
    },
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, sessionActionCreators, navActionCreators), dispatch)
        }
    }
)(withRouter(SessionSelector))