import React from 'react';
import { Link } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import ReactTable from 'react-table';
import moment from 'moment-timezone';
import { LargeListSelect } from '../../../lis-shared/lis-layout/components/lis-forms-component';

class BillGridSection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedSessionBill: null,
            ldNumberSearch: '',
            searchingLDNumber: false,
            ldWarning: false
        }

        this.selectSessionBill = this.selectSessionBill.bind(this);
        this.handleLdNumberSearchChange = this.handleLdNumberSearchChange.bind(this);
        this.searchByLDNumber = this.searchByLDNumber.bind(this);
    }

    componentDidUpdate(prevProps) {
        if ((this.state.selectedSessionBill && prevProps.selectedBill.LegislationNumber !== this.props.selectedBill.LegislationNumber && this.props.selectedBill.LegislationNumber !== this.state.selectedSessionBill.LegislationNumber) || prevProps.legislationVersionID !== this.props.legislationVersionID) {
            this.setState({ selectedSessionBill: null })
        }
    }

    selectSessionBill(legislation) {
        this.setState({ selectedSessionBill: legislation })
        this.props.selectBill(legislation, legislation.LegislationNumber);
    }

    handleLdNumberSearchChange(val) {
        this.setState({ ldNumberSearch: val })
    }

    searchByLDNumber(e) {
        if (e) e.preventDefault()
        if (!["G", "g"].includes(this.state.ldNumberSearch[this.state.ldNumberSearch.length - 1])) {
            this.setState({
                ldWarning: true
            })
            return;
        }
        this.setState({ searchingLDNumber: true, ldWarning: false }, () => {
            this.props.selectBill(null, null, null, this.state.ldNumberSearch, () => {
                this.setState({ searchingLDNumber: false })
            });
        })
    }

    render() {
        let columns = [
            {
                Header: 'Bill #',
                sortable: true,
                sortMethod: ((rowA, rowB, desc) => {
                    const legChamberA = rowA[0];
                    const legChamberB = rowB[0];
                    if (legChamberA !== legChamberB) {
                        return legChamberA.localeCompare(legChamberB);
                    }

                    const legTypeA = rowA[1];
                    const legTypeB = rowB[1];
                    if (legTypeA !== legTypeB) {
                        return legTypeA.localeCompare(legTypeB);
                    }

                    const legKeyA = rowA.substr(2);
                    const legKeyB = rowB.substr(2);
                    return legKeyA - legKeyB;
                }),
                filterable: false,
                width: 90,
                accessor: "LegislationNumber",
                Cell: ({ original, index }) => {
                    const checked = this.props.enrolledPrintQueue.find(b => b.LegislationTextID === original.LegislationTextID);
                    if (original.SessionCode) {
                        return <div>
                            <Link to={`/bill-details/${original.SessionCode}/${original.LegislationNumber}`} target="_blank" rel="noreferrer">{original.LegislationNumber}</Link>
                            {!this.props.completedItems && original.VersionDate && this.props.legislationVersionID === 3 && <input id="sr-publish" type="checkbox" style={{ float: 'right' }} disabled={this.props.selectedBill && this.props.selectedBill.LegislationTextID === original.LegislationTextID ? true : false} onChange={(e) => this.props.checkBill(original, true, e)} checked={checked ? checked : false} />}
                        </div>
                    } else {
                        return (<span>{original.LegislationNumber}</span>)
                    }
                }
            },
            {
                Header: 'Patron',
                sortable: true,
                filterable: false,
                accessor: "Patrons",
                width: 100,
                Cell: row =>
                    <span>{row.value && row.value.map((patron, index) => <React.Fragment key={index}>{patron.PatronDisplayName}{index !== row.value.length - 1 && ", "}</React.Fragment>)}</span>
            },
            {
                Header: 'Caption',
                sortable: true,
                filterable: false,
                accessor: "Description",
                Cell: row => <span>{row.value}</span>
            },
            {
                Header: this.props.completedItems
                    ? <>Published Date <input
                        id={'pd-checkbox-selectall'}
                        type="checkbox"
                        className="checkbox"
                        checked={this.props.checkAll}
                        onChange={this.props.toggleCheckAll}
                    /></>
                    : this.props.legislationVersionID === 2 ? 'Engrossed On' : this.props.legislationVersionID === 6 ? 'Reengrossed On' : [3, 7].includes(this.props.legislationVersionID) ? 'Passed on' : 'Candidate Date',
                sortable: false,
                filterable: false,
                accessor: this.props.completedItems ? "" : "CandidateDate",
                width: 135,
                Cell: ({ original, index }) => {
                    if (!this.props.completedItems) { return <span>{original.CandidateDate ? moment(original.CandidateDate).format("MM/DD/YYYY") : [2, 3, 6, 7].includes(this.props.legislationVersionID) ? "Pending" : ""}</span> }
                    else {
                        const checked = this.props.checkedBills.find(b => b.LegislationTextID === original.LegislationTextID);
                        return (<div>
                            <span>{checked && this.props.customPublishDate ? moment(this.props.customPublishDate).format("MM/DD/YYYY") : original.VersionDate ? moment(original.VersionDate).format("MM/DD/YYYY") : ""}</span>
                            {original.VersionDate && <input id="sr-publish" type="checkbox" style={{ float: 'right' }} disabled={this.props.selectedBill && this.props.selectedBill.LegislationTextID === original.LegislationTextID ? true : false} onChange={(e) => this.props.checkBill(original, false, e)} checked={checked ? checked : false} />}
                        </div>)
                    }
                }
            },
            {
                Header: 'Actions',
                sortable: false,
                filterable: false,
                accessor: "",
                width: 90,
                Cell: ({ original, index }) =>
                    <div className="committee-action-column">
                        {this.props.billDataIsLoading === index ?
                            <div><span className="small-spinner"></span></div>
                            :
                            <button className="button edit" type="button" onClick={() => this.props.selectBill(original, index)}>Edit</button>
                        }
                        {original.PendingChange
                            ? <span className="icon flag"></span>
                            : <div />
                        }
                        {original.IsComplete
                            ? !this.props.completedItems
                                ? [3, 7].includes(this.props.legislationVersionID) && <i className="icon paper-yellow" style={{ cursor: "default" }} />
                                : <a className="icon book" href={`/bill-details/${original.SessionCode}/${original.LegislationNumber}/text/${original.DocumentCode}`} target="_blank" rel="noreferrer" />
                            : null
                        }
                    </div>
            }
        ];

        if (this.props.completedItems) {
            let docCodeColumn = {
                Header: 'Doc Code',
                sortable: true,
                filterable: false,
                accessor: "DocumentCode",
                width: 110,
                Cell: row =>
                    <span>{row.value}</span>
            }

            columns.splice(1, 0, docCodeColumn)
        }

        if (!this.props.completedItems) {
            let publishDateColumn = {
                Header: <>Publish On <input
                    id={'pd-checkbox-selectall'}
                    type="checkbox"
                    className="checkbox"
                    checked={this.props.checkAll}
                    onChange={this.props.toggleCheckAll}
                /></>,
                sortable: false,
                filterable: false,
                accessor: "",
                width: 135,
                Cell: ({ original, index }) => {
                    const checked = this.props.checkedBills.find(b => b.LegislationTextID === original.LegislationTextID);
                    return (<div>
                        <span>{checked && this.props.customPublishDate ? moment(this.props.customPublishDate).format("MM/DD/YYYY") : original.VersionDate ? moment(original.VersionDate).format("MM/DD/YYYY") : ""}</span>
                        {original.VersionDate && <input id="sr-publish" type="checkbox" style={{ float: 'right' }} disabled={((this.props.selectedBill && this.props.selectedBill.LegislationID === original.LegislationID) || (!original.CandidateDate && ![2, 3, 6, 7].includes(this.props.legislationVersionID))) ? true : false} onChange={(e) => this.props.checkBill(original, false, e)} defaultChecked={checked} />}
                    </div>)
                }
            }

            columns.splice(4, 0, publishDateColumn)
        }

        const billListIDs = this.props.billList && this.props.billList.length ? this.props.billList.map(b => b.LegislationID) : null;
        return (
            <div>
                <div>
                    <div style={{ display: 'flex', height: (this.props.billList && this.props.billList.length) || [9, 10].includes(this.props.legislationVersionID) ? '25px' : null }}>
                        <div style={{ width: '90px' }}>
                            {this.props.completedItems && ((this.props.billList && this.props.billList.length) || this.props.reprintsFiltered) ? <button type="button" className="button" onClick={this.props.filterReprints} style={{ left: '0px', position: 'absolute' }}>{this.props.reprintsFiltered ? 'Show All Legislation' : 'Only Show Reprints'}</button> : null}
                            {!this.props.completedItems && [9, 10].includes(this.props.legislationVersionID) &&
                                <div style={{ display: 'flex' }}>
                                    <LargeListSelect
                                        className="grid-selector-dropdown"
                                        classNamePrefix="grid-selector-dropdown"
                                        placeholder={"Add a bill..."}
                                        options={[...this.props.sessionBillsList].filter(sb => billListIDs && billListIDs.length ? !billListIDs.includes(sb.LegislationID) : true)}
                                        onChange={(value) => { this.selectSessionBill(value) }}
                                        styles={{ container: provided => ({ ...provided, width: '130px', position: 'absolute' }) }}
                                        value={this.state.selectedSessionBill}
                                    />
                                    <div style={{ left: '135px', position: 'absolute' }}>
                                        {this.state.selectedSessionBill && this.props.billDataIsLoading === this.state.selectedSessionBill.LegislationNumber ?
                                            <span className="small-spinner"></span> : this.props.legislationVersionID === 10 ? <span style={{ fontSize: '12px' }}>OR</span> : null
                                        }
                                    </div>
                                    {this.props.legislationVersionID === 10 &&
                                        <form className="inner-grid" style={{ left: '160px', width: '180px', position: 'absolute', gridTemplateColumns: '1fr min-content min-content' }}>
                                            <input
                                                placeholder='Add by LD Number'
                                                onChange={(e) => this.handleLdNumberSearchChange(e.target.value)}
                                                value={this.state.ldNumberSearch}
                                            />
                                            {this.state.ldNumberSearch && <button className="button" type="submit" disabled={this.state.searchingLDNumber} onClick={(e) => this.searchByLDNumber(e)}>{this.state.searchingLDNumber ? <div><span className="small-spinner"></span></div> : 'Search'}</button>}
                                            {this.state.ldWarning && <span style={{ whiteSpace: "nowrap", color: "red", fontSize: "13px" }}>Governor's Recommendation LD numbers must end with a G</span>}
                                        </form>
                                    }
                                </div>
                            }
                            {!this.props.completedItems && this.props.legislationVersionID === 3 &&
                                <button type="button" className="button" disabled={this.props.isSaving || !this.props.enrolledPrintQueue || !this.props.enrolledPrintQueue.length} onClick={this.props.batchComplete} style={{ position: 'relative' }}>Print Parchment</button>
                            }
                        </div>
                        <div style={{ width: "65px" }} />
                        <div style={{ width: '200px' }}>
                            {!this.props.completedItems && this.props.legislationVersionID === 3 && ((this.props.billList && this.props.billList.length) || this.props.reprintsFiltered) &&
                                <button type="button" className="button" disabled={this.props.isSaving || !this.props.enrolledPrintQueue || !this.props.enrolledPrintQueue.length || !this.props.checkedBills || !this.props.checkedBills.length} onClick={() => { this.props.batchComplete(); this.props.batchPublish(); }} style={{ position: 'relative' }}>{"Print Parchment & " + (this.props.completedItems ? 'Republish' : 'Publish')}</button>
                            }
                        </div>
                        <div style={{ flex: '100 0 auto', width: '100px' }} />
                        <div style={{ width: '135px' }} />
                        <div style={{ width: '135px' }}>
                            {((this.props.billList && this.props.billList.length) || this.props.reprintsFiltered) ? <button type="button" className="button" disabled={this.props.isSaving || !this.props.checkedBills || !this.props.checkedBills.length} onClick={this.props.batchPublish} style={{ width: '135px', position: 'absolute' }}>{this.props.completedItems ? 'Batch Republish' : 'Batch Publish'}</button> : null}
                            {this.props.checkedBills && this.props.checkedBills.length ?
                                <div className="flex-row flex-vertical-center flex-start" style={{ right: '91px', marginRight: '135px', position: 'absolute', height: '35px' }} >
                                    <div className="toggle-switch" onClick={this.props.togglePublishDatePreference}>
                                        <input id="sr-status-change" disabled={this.props.isSaving} readOnly checked={!this.props.useCurrentPublishDates} type="checkbox" />
                                        <span className="slider"></span>
                                    </div>
                                    <label htmlFor="sr-status-change" style={{ padding: '0.25em 5px', margin: '0px' }}>Use custom publish date</label>
                                </div>
                                :
                                null
                            }
                            {this.props.checkedBills && this.props.checkedBills.length && !this.props.useCurrentPublishDates ?
                                <div id="publish-date-datepicker" style={{ marginRight: '330px', position: 'absolute' }}>
                                    <DatePicker
                                        isClearable
                                        selected={this.props.customPublishDate}
                                        onChange={this.props.handlePublishDateChange}
                                    />
                                </div>
                                :
                                null
                            }
                        </div>
                        <div style={{ width: '90px' }} />
                    </div>
                </div>
                {
                    this.props.reprintsFiltered && this.props.checkedBills && this.props.checkedBills.length && this.props.checkedBills.filter(b => !b.IsReprint).length ?
                        <div style={{ display: 'flex' }}>
                            <span className="input-feedback" style={{ marginBottom: '0px' }}>You have checked legislation that are no longer in view as you are only viewing reprints.</span>
                        </div>
                        : null
                }
                <div>
                    <ReactTable
                        resizable={false}
                        data={this.props.billList}
                        loading={!this.props.isLoaded}
                        ref={r => this.reactTable = r}
                        columns={columns}
                        minRows={1}
                        defaultPageSize={20}
                        pageSizeOptions={[5, 10, 15, 20, 25, 50, 100]}
                        className="committee-table -striped -highlight react-table-hide-no-data"
                    />
                </div>
            </div>
        );
    }
}

export default BillGridSection;