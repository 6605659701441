import React from 'react';
import { Link } from 'react-router-dom';
import CalendarDetailsComponent from '../../lis-shared/lis-calendar/lis-calendar-details';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { sessionActionCreators } from '../../stores/lis-session-store';
import { navActionCreators } from '../../stores/lis-nav-store';
import { calendarActionCreators } from '../../stores/lis-calendar-store';
import { authActionCreators } from '../../stores/lis-auth-store';
import { cancelRequest } from '../../services/request.service';
import renderHTML from 'react-render-html';
import { HubConnectionBuilder } from "@microsoft/signalr";

import moment from 'moment-timezone';

import { collectionActionCreators } from '../../stores/lis-collection-store';
import { billActionCreators } from '../../stores/lis-legislation-store';
import { communicationsActionCreators } from '../../stores/lis-communications-store';

const API_URL = window.env ? window.env.API_SERVER : '';
const lisDocDomain = window.env?.STORAGE_ACCOUNT_DOMAIN;

class PublicCalendarDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            showAll: false,
            calendar: '',
            creatingCollection: null,
            createdCollections: [],
            currentItemHubConnection: '',
            commonCurrentItemOptions: [
                { label: "Clear current item", value: 0 },
                { label: "At Ease", value: 1 },
                { label: "In Recess", value: 2 },
                { label: "Adjourned", value: 3 },
                { label: "Morning Hour", value: 4 },
                { label: "Closing Statements", value: 5 },
                { label: "Point of Personal Privilege", value: 6 },
                { label: "Taking up Budget Bill", value: 7 },
                { label: "Electing Judges", value: 8 },
                { label: "Governor Addressing Joint Session", value: 9 },
                { label: "Taking up Memorial Resolutions", value: 10 },
                { label: "Taking up Commending Resolutions", value: 11 },
                { label: "Other Business", value: 12 }
            ],
            failedToJoinBills: [],
            showPdfWindow: false
        };

        this.receiveCurrentItem = this.receiveCurrentItem.bind(this);
        this.setCurrentItem = this.setCurrentItem.bind(this);
        this.toggleAgenda = this.toggleAgenda.bind(this);
        this.createWatchlist = this.createWatchlist.bind(this);
        this.editHistory = this.editHistory.bind(this);
        this.removeZeros = this.removeZeros.bind(this);
        this.generateClerksCopy = this.generateClerksCopy.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.actions.getCalendarCategoriesReferences();
        const getCalendar = this.props.actions.getCalendarById(`?ReferenceNumber=${this.props.match.params.calendarid}&SessionCode=${this.props.match.params.sessioncode}`)
            .then(() => {
                this.props.actions.getSessionById(this.props.calendar.calendarGet.SessionID);
                // If the calendar has zero categories then it is probably legacy data.
                // The exception is that Pro Forma calendars have zero bills on them.                
                // OR If the CalendarCategory at index 0 is the default calendar category template with no CalendarCategoryID from the DB
                if (this.props.calendar.calendarGet.CalendarCategories.length === 0 && !this.props.calendar.calendarGet.IsProforma || this.props.calendar.calendarGet.CalendarCategories[0] && this.props.calendar.calendarGet.CalendarCategories[0].CalendarCategoryID === undefined) {
                    const htmlFile = this.props.calendar.calendarGet.CalendarFiles.find(file => file.FileURL.toUpperCase().includes('.HTM'));
                    if (htmlFile) {
                        return this.props.actions.getFile(htmlFile.FileURL)
                            .then(() => {
                                let html = this.props.nav.file;
                                if (html) {
                                    html = html.replaceAll('href="#', 'href="' + window.location.pathname + "#")
                                }
                                this.setState({
                                    calendarFile: html,
                                    isLoaded: true
                                });
                                return Promise.resolve();
                            }).catch(err => {
                                if (err !== 'Aborted') {
                                    throw err.toString();
                                }
                                return Promise.reject(err);
                            });
                    } else {
                        return Promise.resolve();
                    }
                } else {
                    return Promise.resolve()
                }
            }).catch(err => {
                return Promise.reject(err);
            });
        Promise.all([
            getCalendar
        ])
            .then(() => {
                const calendarGet = this.props.calendar.calendarGet
                this.setState({
                    isLoaded: true,
                    calendar: calendarGet
                })
                // Connect to websocket for calendar current item
                let currentItemConnection = new HubConnectionBuilder()
                    .withUrl((API_URL || process.env.REACT_APP_CALENDAR_API_URL) + '/Calendar/currentitem')
                    .build();
                //Will change to a more reasonable timeout later
                currentItemConnection.serverTimeoutInMilliseconds = 5000000000;
                this.setState({
                    currentItemHubConnection: currentItemConnection
                }, () => {
                    this.state.currentItemHubConnection
                        .start({ withCredentials: false })
                        .then(() => {
                            this.state.currentItemHubConnection.invoke('GetCalendarCurrentItem', calendarGet.CalendarID)
                                .then((message) => {
                                    const currentItem = message.stronglyTypedObject;
                                    if (currentItem) {
                                        this.setState({
                                            currentCategoryItem: currentItem[0].calendarCategoryID,
                                            currentAgendaItem: currentItem[0].agendaID,
                                            currentProceduralMotion: currentItem[0].proceduralMotion
                                        })

                                    }
                                })
                        })
                        .catch(err => {
                            console.log('Error while establishing connection :( ' + err)
                        });
                    this.state.currentItemHubConnection.on("Receive", message => this.receiveCurrentItem(message));
                });
            }).catch(err => {
                if (err === 'Aborted') {
                    return;
                }
                console.log(err)
                this.setState({
                    isLoaded: true,
                    message: err.toString()
                });
            });
    }

    componentWillUnmount() {
        cancelRequest();
    }

    receiveCurrentItem(message) {
        try {
            const currentItem = JSON.parse(message.message);
            this.setState({
                currentCategoryItem: currentItem[0].CalendarCategoryID,
                currentAgendaItem: currentItem[0].AgendaID,
                currentProceduralMotion: currentItem[0].ProceduralMotion
            })
        } catch {
            console.error(`Message malformed: ${message}`);
        }
    }

    setCurrentItem(e, categoryId, agendaId, proceduralMotion) {
        if (e) {
            e.stopPropagation();
        }
        if (proceduralMotion === 'Clear current item') {
            // This is so the user has ability to set clear out the current item and display nothing.
            proceduralMotion = '';
        }
        const params = {
            CalendarID: this.state.calendar.CalendarID,
            CalendarCategoryID: categoryId || null,
            AgendaID: agendaId || null,
            ProceduralMotion: proceduralMotion || ''
        }
        this.setState({
            isSavingCalendarCurrentItem: true
        });
        this.props.actions.saveCalendarCurrentItem(params)
            .then(() => {
                this.setState({
                    isSavingCalendarCurrentItem: false
                });
            })
            .catch(err => {
                if (err === 'Aborted') {
                    return;
                }
                console.error(err);
                this.props.actions.makeToast([{ message: "Failed To Set Current Item", type: "failure" }]);
            });
    }

    toggleAgenda(catIndex, agendaIndex) {
        let calendar = { ...this.state.calendar };
        let agenda = calendar.CalendarCategories[catIndex].Agendas[agendaIndex];
        agenda.show = !agenda.show;
        if (agenda.IsActive && agenda.LegislationNumber && !agenda.Versions) {
            agenda.Versions = []
            this.props.actions.getBillVersionByBillNumber('?BillNumber=' + calendar.CalendarCategories[catIndex].Agendas[agendaIndex].LegislationNumber + '&SessionID=' + calendar.SessionID)
                .then(() => {
                    // Get the calendar data again just in case the user toggled the agenda items
                    calendar = { ...this.state.calendar };
                    let versions = this.props.bills.billVersion.filter(ver => moment(ver.DraftDate).startOf('day').isSameOrBefore(calendar.CalendarDate))
                    // Most recent versions first
                    versions = versions.sort((a, b) => moment(a.DraftDate).isSameOrBefore(b.DraftDate))
                    calendar.CalendarCategories[catIndex].Agendas[agendaIndex].Versions = versions
                    this.setState({
                        calendar: calendar
                    });
                }).catch(err => {
                    if (err === "Aborted") {
                        return
                    }
                    calendar = { ...this.state.calendar };
                    calendar.CalendarCategories[catIndex].Agendas[agendaIndex].Versions = "fail";
                    this.setState({
                        calendar: calendar
                    });
                })
        }
        this.setState({
            calendar: calendar
        });
    }

    createWatchlist(e, catIndex, callback) {
        e.stopPropagation();
        const category = this.state.calendar.CalendarCategories[catIndex];
        let collection = {
            Name: `Calendar on ${moment(this.state.calendar.CalendarDate).format('MM/DD/YY')}`,
            Description: category.Description,
            WatchListLegislation: []
        };
        category.Agendas.forEach(agenda => {
            if (agenda.LegislationID && agenda.IsActive) {
                collection.WatchListLegislation.push({ LegislationID: agenda.LegislationID });
            }
        });
        if (collection.WatchListLegislation.length > 0) {
            this.setState({
                creatingCollection: catIndex
            });
            this.props.actions.saveCollections({ WatchLists: [collection] }, true)
                .then(() => {
                    let createdCollections = [...this.state.createdCollections];
                    createdCollections[catIndex] = [...this.props.collection.collectionSave][0]
                    this.setState({
                        creatingCollection: null,
                        createdCollections: createdCollections
                    });
                    if (callback) {
                        callback(this.props.collection.collectionSave);
                    }
                }).catch(err => {
                    if (err === 'Aborted') {
                        return;
                    }
                    this.setState({
                        creatingCollection: null
                    });
                    this.props.actions.makeToast([{ message: "Failed To Create Watchlist", type: "failure" }]);
                });
        }
    }

    editHistory(e, catIndex, displayDescription) {
        //User wants to be able to click a button on the calendar and immediately be able to edit the bills in a category.
        //Loop the category agendas, build a string of legislationIDs, put it in the query object, and ship it to bill management
        const category = this.state.calendar.CalendarCategories[catIndex];

        let legislationIds = [];
        for (let i = 0; i < category.Agendas.length; i++) {
            if (category.Agendas[i].IsActive && !category.Agendas[i].IsHidden)
                legislationIds.push({ LegislationID: category.Agendas[i].LegislationID })
        }

        const queryString = JSON.stringify({ legislationIDs: legislationIds, selectedSession: this.state.calendar.SessionID, calendarDate: this.state.calendar.CalendarDate, calendarCategoryTypeID: category.CalendarCategoryTypeID, calendarCategoryDescription: displayDescription })
        const link = "/bill-management?q=" + window.btoa(queryString)
        this.props.history.push(link);
    }

    removeZeros(doc) {
        let dom = document.createElement('div');
        dom.innerHTML = doc;

        let all = document.getElementsByTagName("*");

        for (let i = 0; i < all.length; i++) {
            let trimmedUrl = ""
            //If it's an href with a bill or resolution number in it then slice off the end, remove the zeros, and append it back to the href
            if (all[i].href && all[i].href != "" && all[i].href.match(/[H|S][B|J|R][0-9]{4}/)) {
                trimmedUrl = all[i].href.slice(all[i].href.length - 7, all[i].href.length - 1)
                trimmedUrl = trimmedUrl.slice(0, 2) + parseInt(trimmedUrl.slice(2))
                all[i].href = all[i].href.slice(0, all[i].href.length - 7) + trimmedUrl + "/";
            }
        }

        return doc;
    }

    generateClerksCopy(category) {
        let LegislationFiles = [];

        category.Agendas.filter(x => x.IsActive).forEach((x, i) => {
            LegislationFiles.push({
                LegislationID: x.LegislationID,
                LegislationNumber: x.LegislationNumber,
                SessionID: this.state.calendar.SessionID,
                AmendmentCount: 1,
                FirstChamberCount: 1,
                SecondChamberCount: 1
            })
        })

        this.props.actions.joinCommFile({ LegislationFiles })
            .then(() => {
                const res = this.props.communications.joinCommFile;
                if (res.Pdf.FileContents) {
                    fetch(`data:application/pdf;base64,${res.Pdf.FileContents}`)
                        .then(res => res.blob())
                        .then(blob => {
                            const urlObj = URL.createObjectURL(blob);
                            this.setState({
                                pdfSrc: urlObj
                            });
                        });
                } else {
                    this.setState({
                        pdfSrc: ''
                    })
                }
                let failedBills = [];
                res.JoinLegislation.forEach(leg => {
                    if (!leg.Generated) {
                        const bill = LegislationFiles.find(bill => bill.LegislationID === leg.LegislationID)
                        if (bill && !failedBills.includes(bill)) {
                            failedBills.push(bill)
                        }
                    }
                });

                if (failedBills.length === LegislationFiles.length) {
                    // Every single bill in the list does not have a clerk copy
                    this.props.actions.makeToast([{ message: LegislationFiles.length === 1 ? LegislationFiles[0].LegislationNumber + " does not have a clerk copy" : "No selected bills have a clerk copy", type: "failure", long: true }]);
                    this.setState({
                        gettingPdf: false
                    });
                } else {
                    this.setState({
                        failedToJoinBills: failedBills,
                        showPdfWindow: true,
                        gettingPdf: false
                    });
                }
            }).catch(err => {
                if (err === 'AbortError') {
                    return;
                }
                this.setState({
                    pdfSrc: '',
                    gettingPdf: false
                });
                console.log(err.toString());
                this.props.actions.makeToast([{ message: "Failed To Get Data", type: "failure" }]);
            })
    }

    render() {
        const { selectedSession } = this.props.session;
        const { isLoaded, message, calendar } = this.state;
        if (!isLoaded) {
            return (<div className="center-spinner spinner">Loading...</div>)
        }
        if (message) {
            return (<div className="center">{message}</div>)
        }
        if (this.state.calendarFile) {

            //Find and display the PDF link if we're displaying a pre-generated calendar file
            let calendarFileUrl = '';
            const pdfFile = this.props.calendar.calendarGet.CalendarFiles.find(file => file.FileURL.toUpperCase().includes('PDF'));
            if (pdfFile) {
                const url = new URL(pdfFile.FileURL);
                calendarFileUrl = lisDocDomain + (url.pathname[0] === '/' ? url.pathname : '/' + url.pathname);
            }

            return (
                <div className='details-page-data-container'>
                    {calendarFileUrl != "" &&
                        <a target="_blank" href={calendarFileUrl} className="float-right">
                            <span title="PDF" aria-label="PDF" className="icon pdf"></span>
                        </a>
                    }
                    {renderHTML(this.removeZeros(this.state.calendarFile))}
                </div>
            );
        }

        //This calendarTime logic is also set in lis-calendar-form, if changed here, change there as well
        const calendarTime = moment.utc(calendar.CalendarDate).isSame(moment.utc(calendar.CalendarDate).hour(0), "minute")
            || moment.utc(calendar.CalendarDate).isSame(moment.utc(calendar.CalendarDate).hour(12), "minute")
            ? calendar.ReferenceNumber !== "HC10402" ? "12 noon" : ""
            : moment.utc(calendar.CalendarDate).format("h:mm a");

        return (<div className='generic-details-page calendar-details-page'>
            <div style={{ display: this.state.showPdfWindow ? 'block' : 'none', overflow: 'hidden' }} onClick={() => this.setState({ showPdfWindow: false })} className="overlay center-content">
                <div className="full full-height" style={{ maxWidth: '100vw', width: '95%' }}>
                    <div onClick={e => e.stopPropagation()} className="dlas-forms user-forms overlay-content animated-fade-in-up full-height">
                        <div>
                            <button className="button print" onClick={() => this.pdfRef ? this.pdfRef.contentWindow.print() : null}> Print</button>
                        </div>
                        {this.state.failedToJoinBills.length > 0 &&
                            <div className="small-text">
                                <span>These bills do not have clerk copies and are not included in the PDF: </span>
                                <span>{this.state.failedToJoinBills.map((bill, i) => <React.Fragment key={i}>{i !== 0 && ", "}{bill.billNum}</React.Fragment>)}</span>
                            </div>
                        }
                        <div className="center full-height">
                            <iframe className="center" style={{ width: '100%', height: '90%' }} ref={el => this.pdfRef = el} src={this.state.pdfSrc}></iframe>
                            <button style={{ position: 'absolute', bottom: '10px', right: '10px' }} type="button" onClick={() => this.setState({ showPdfWindow: false, pdfSrc: "" })} className="button secondary">Close</button>
                        </div>
                    </div>
                </div>
            </div>
            <CalendarDetailsComponent
                calendar={calendar}
                calendarTime={calendarTime}
                session={selectedSession}
                sessionList={this.props.session.sessionList}
                comments={calendar.CalendarComments || []}
                creatingCollection={this.state.creatingCollection}
                userClaims={this.props.login.userClaims}
                createdCollections={this.state.createdCollections}
                editHistory={this.editHistory}
                createWatchlist={this.createWatchlist}
                toggleAgenda={this.toggleAgenda}
                setCurrentItem={this.setCurrentItem}
                currentCategoryItem={this.state.currentCategoryItem}
                currentAgendaItem={this.state.currentAgendaItem}
                currentProceduralMotion={this.state.currentProceduralMotion}
                commonCurrentItemOptions={this.state.commonCurrentItemOptions}
                isSavingCalendarCurrentItem={this.state.isSavingCalendarCurrentItem}
                generateClerksCopy={this.generateClerksCopy}
                calendarCategoryReferences={this.props.calendar.calendarCategoriesReferences}
            />
        </div>);
    }
}

export default connect(
    (state) => {
        const { session, nav, calendar, bills, login, collection, communications } = state;
        return {
            session,
            nav,
            calendar,
            bills,
            login,
            collection,
            communications
        }
    },
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, sessionActionCreators, navActionCreators, calendarActionCreators, billActionCreators, authActionCreators, collectionActionCreators, communicationsActionCreators), dispatch)
        }
    }
)(PublicCalendarDetails)
