import React from "react";
import { LargeListSelect } from '../../../lis-shared/lis-layout/components/lis-forms-component';
import Select from 'react-select';
import ReactTooltip from 'react-tooltip';
import { Link } from 'react-router-dom';
import renderHTML from 'react-render-html';
import AgendaItemsComponent from '../../../lis-shared/lis-calendar/lis-agenda-items';
import moment from 'moment-timezone';
import { Editor } from '@tinymce/tinymce-react';

const isNotHidden = (agenda, category, committeeList) => (!agenda.CommitteeID && !agenda.IsHidden) || (agenda.IsActive && !agenda.IsHidden) || (committeeList.find(x => x.value == agenda.CommitteeID) && category.CalendarCategoryTypeID === 66) /* Bills or Resolutions Rereferred */

const NUMERIC_SORT_CATEGORIES = [2, 3, 4, 26, 27, 5, 41, 6, 7, 28, 29, 30, 31, 32, 57, 33, 35, 45, 9, 10, 8, 11, 13, 37, 38, 44, 46, 22];

const sortAgendas = (a, b, calendarChamber) => {
    return !a.IsActive && b.IsActive
        ? 1
        : a.IsActive && !b.IsActive
            ? -1
            : (!a.Ranking || a.Ranking === "") && b.Ranking
                ? -1
                : (a.Ranking && a.Ranking !== "") && (!b.Ranking || b.Ranking === "")
                    ? 1
                    : a.Ranking > b.Ranking
                        ? 1
                        : a.Ranking < b.Ranking
                            ? -1
                            : moment(a.CalendarDate).isAfter(b.CalendarDate, "day")
                                ? 1
                                : moment(b.CalendarDate).isAfter(a.CalendarDate, "day")
                                    ? -1
                                    : moment(a.CandidateDate).isAfter(b.CandidateDate, "day")
                                        ? 1
                                        : moment(b.CandidateDate).isAfter(a.CandidateDate, "day")
                                            ? -1
                                            : a.LegislationNumber && a.LegislationNumber[0].localeCompare(b.LegislationNumber[0]) !== 0 ?
                                                a.LegislationNumber[0].localeCompare(calendarChamber) !== 0
                                                    ? -1 : 1
                                                : a.LegislationNumber && a.LegislationNumber[1].localeCompare(b.LegislationNumber[1]) > 0
                                                    ? 1
                                                    : a.LegislationNumber && a.LegislationNumber[1].localeCompare(b.LegislationNumber[1]) < 0
                                                        ? -1
                                                        : a.LegislationKey > b.LegislationKey
                                                            ? 1
                                                            : -1
}

const sortAgendasNumerically = (a, b, calendarChamber) => {
    return !a.IsActive && b.IsActive
        ? 1
        : a.IsActive && !b.IsActive
            ? -1
            : (!a.Ranking || a.Ranking === "") && (b.Ranking && b.Ranking !== "")
                ? -1
                : (a.Ranking && a.Ranking !== "") && (!b.Ranking || b.Ranking === "")
                    ? 1
                    : a.Ranking > b.Ranking
                        ? 1
                        : a.Ranking < b.Ranking
                            ? -1
                            : a.LegislationNumber && a.LegislationNumber[0].localeCompare(b.LegislationNumber[0]) !== 0 ?
                                a.LegislationNumber[0].localeCompare(calendarChamber) !== 0
                                    ? -1 : 1
                                : a.LegislationNumber && a.LegislationNumber[1].localeCompare(b.LegislationNumber[1]) > 0
                                    ? 1
                                    : a.LegislationNumber && a.LegislationNumber[1].localeCompare(b.LegislationNumber[1]) < 0
                                        ? -1
                                        : a.LegislationKey > b.LegislationKey
                                            ? 1
                                            : -1
}

const sortInactiveAgendas = (a, b, calendarChamber) => {
    return (!a.Ranking || a.Ranking === "") && (b.Ranking && b.Ranking !== "")
        ? -1
        : (a.Ranking && a.Ranking !== "") && (!b.Ranking || b.Ranking === "")
            ? 1
            : a.Ranking > b.Ranking
                ? 1
                : a.Ranking < b.Ranking
                    ? -1
                    : moment(a.CalendarDate).isAfter(b.CalendarDate, "day")
                        ? 1
                        : moment(b.CalendarDate).isAfter(a.CalendarDate, "day")
                            ? -1
                            : moment(a.CandidateDate).isAfter(b.CandidateDate, "day")
                                ? 1
                                : moment(b.CandidateDate).isAfter(a.CandidateDate, "day")
                                    ? -1
                                    : a.LegislationNumber && a.LegislationNumber[0].localeCompare(b.LegislationNumber[0]) !== 0 ?
                                        a.LegislationNumber[0].localeCompare(calendarChamber) !== 0
                                            ? -1 : 1
                                        : a.LegislationNumber && a.LegislationNumber[1].localeCompare(b.LegislationNumber[1]) > 0
                                            ? 1
                                            : a.LegislationNumber && a.LegislationNumber[1].localeCompare(b.LegislationNumber[1]) < 0
                                                ? -1
                                                : a.LegislationKey > b.LegislationKey
                                                    ? 1
                                                    : -1
}

const sortInactiveAgendasNumerically = (a, b, calendarChamber) => {
    return (!a.Ranking || a.Ranking === "") && (b.Ranking && b.Ranking !== "")
        ? -1
        : (a.Ranking && a.Ranking !== "") && (!b.Ranking || b.Ranking === "")
            ? 1
            : a.Ranking > b.Ranking
                ? 1
                : a.Ranking < b.Ranking
                    ? -1
                    : a.LegislationNumber && a.LegislationNumber[0].localeCompare(b.LegislationNumber[0]) !== 0 ?
                        a.LegislationNumber[0].localeCompare(calendarChamber) !== 0
                            ? -1 : 1
                        : a.LegislationNumber && a.LegislationNumber[1].localeCompare(b.LegislationNumber[1]) > 0
                            ? 1
                            : a.LegislationNumber && a.LegislationNumber[1].localeCompare(b.LegislationNumber[1]) < 0
                                ? -1
                                : a.LegislationKey > b.LegislationKey
                                    ? 1
                                    : -1
}

const findLastByIsActiveThenCalendarDateThenCandidateDateThenLegChamberTypeKey = (a, b, calendarChamber, ignoreCalendarDate, ignoreCandidateDate) => (
    a.IsActive && findLastByCalendarDateThenCandidateDateThenLegChamberTypeKey(a, b, calendarChamber, ignoreCalendarDate, ignoreCandidateDate)
)

const findLastByCalendarDateThenCandidateDateThenLegChamberTypeKey = (a, b, calendarChamber, ignoreCalendarDate, ignoreCandidateDate) => (
    (((ignoreCalendarDate || (a.CalendarDate && b.CalendarDate && moment(a.CalendarDate).isSame(b.CalendarDate, "day"))) && findLastByLegChamberTypeKey(a, b, calendarChamber)) ||
        (!ignoreCalendarDate && a.CalendarDate && b.CalendarDate && moment(a.CalendarDate).isBefore(b.CalendarDate, "day"))) ||
    (((ignoreCandidateDate || (a.CandidateDate && b.CandidateDate && moment(a.CandidateDate).isSame(b.CandidateDate, "day"))) && findLastByLegChamberTypeKey(a, b, calendarChamber)) ||
        (!ignoreCandidateDate && a.CandidateDate && b.CandidateDate && moment(a.CandidateDate).isBefore(b.CandidateDate, "day"))) ||
    ((ignoreCalendarDate || (!a.CalendarDate && !b.CalendarDate)) && (ignoreCandidateDate || (!a.CandidateDate && !b.CandidateDate)) &&
        findLastByLegChamberTypeKey(a, b, calendarChamber))
    || (!ignoreCalendarDate && !b.CalendarDate && a.CandidateDate)
    || !ignoreCandidateDate && !b.CandidateDate && a.CandidateDate
)

const findLastByLegChamberTypeKey = (a, b, calendarChamber) => (
    (a.LegislationNumber && a.LegislationNumber[0].localeCompare(b.LegislationNumber[0]) === (calendarChamber === "H" ? 1 : -1)) ||
    (a.LegislationNumber && a.LegislationNumber && a.LegislationNumber[0].localeCompare(b.LegislationNumber[0]) === 0 && a.LegislationNumber[1].localeCompare(b.LegislationNumber[1]) === -1) ||
    (a.LegislationNumber && a.LegislationNumber[0].localeCompare(b.LegislationNumber[0]) === 0 && a.LegislationNumber[1].localeCompare(b.LegislationNumber[1]) === 0 && a.LegislationKey && a.LegislationKey < parseInt(b.LegislationKey))
)

const getItemStyle = (isDragging, draggableStyle, isActive) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    // change background colour if dragging
    background: isDragging ? '#34495e' : 'white',
    color: isDragging ? 'white' : '#34495e',
    border: 'none',
    borderLeft: '1px solid #e0e0e0',
    borderBottom: '1px solid #e0e0e0',
    color: isActive ? '' : '#8b8b8b',
    // styles we need to apply on draggables
    ...draggableStyle,
});

class BillsGrid extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectAllActive: false,
            selectAllInactive: false,
            showAgendaItemEditor: -1,
            showAgendaItemEditorCategoryIndex: -1,
            lastCheckedBills: []
        };
        this.handleAgendaSort = this.handleAgendaSort.bind(this);
        this.toggleSelectAll = this.toggleSelectAll.bind(this);
        this.toggleRow = this.toggleRow.bind(this);
        this.toggleBill = this.toggleBill.bind(this);
        this.showAgendaItems = this.showAgendaItems.bind(this);
        this.toggleEdit = this.toggleEdit.bind(this);
        this.toggleEditCategoryDescription = this.toggleEditCategoryDescription.bind(this);
        this.saveChange = this.saveChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.onDragEnd = this.onDragEnd.bind(this);
        this.toggleAgendaItemEditor = this.toggleAgendaItemEditor.bind(this);
        this.expandTitle = this.expandTitle.bind(this);
        this.getCategoryBills = this.getCategoryBills.bind(this);
        this.setDescription = this.setDescription.bind(this);
        this.setBillsInConferenceCategoryDescription = this.setBillsInConferenceCategoryDescription.bind(this);
        this.categoryDescriptionIsEdited = this.categoryDescriptionIsEdited.bind(this);
        this.changeCommitteeDropdown = this.changeCommitteeDropdown.bind(this);
        this.changeDateDropdown = this.changeDateDropdown.bind(this);
        this.addBill = this.addBill.bind(this);
        this.toggleShowAmendments = this.toggleShowAmendments.bind(this);
        this.setRanking = this.setRanking.bind(this);
        this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
        this.selectAllCommending = this.selectAllCommending.bind(this);
        this.selectAllMemorial = this.selectAllMemorial.bind(this);
    }

    toggleSelectAll(categoryIndex) {
        let organizedBills = [...this.props.organizedBills];
        const opposite = !organizedBills.filter(c => !c.DeletionDate)[categoryIndex].selectAll;
        organizedBills.filter(c => !c.DeletionDate)[categoryIndex].selectAll = opposite
        organizedBills.filter(c => !c.DeletionDate)[categoryIndex].Agendas.forEach(leg => { if (isNotHidden(leg, organizedBills.filter(c => !c.DeletionDate)[categoryIndex], this.props.committeeList)) leg.checked = opposite });
        this.props.updateAgenda(organizedBills);

        let lastCheckedBills = this.state.lastCheckedBills;
        lastCheckedBills[categoryIndex] = -1;
        this.setState({
            lastCheckedBills: lastCheckedBills
        });
    }

    toggleRow(categoryIndex, itemIndex, e) {
        let organizedBills = [...this.props.organizedBills];
        let lastCheckedBills = this.state.lastCheckedBills;
        let lastCheckedBill = lastCheckedBills[categoryIndex];
        if (e.nativeEvent.shiftKey && !isNaN(lastCheckedBill) && lastCheckedBill !== -1) {
            organizedBills.filter(c => !c.DeletionDate)[categoryIndex].Agendas.forEach(agenda => agenda.checked = false);
            for (let i = 0; i < Math.abs(itemIndex - lastCheckedBill); i++) {
                const index = lastCheckedBill + i * (itemIndex - lastCheckedBill) / Math.abs(itemIndex - lastCheckedBill);
                organizedBills.filter(c => !c.DeletionDate)[categoryIndex].Agendas[index].checked = true;
            }
        } else {
            lastCheckedBill = itemIndex;
        }
        organizedBills.filter(c => !c.DeletionDate)[categoryIndex].Agendas[itemIndex].checked = !organizedBills.filter(c => !c.DeletionDate)[categoryIndex].Agendas[itemIndex].checked;
        this.props.updateAgenda(organizedBills);

        lastCheckedBills[categoryIndex] = lastCheckedBill;
        this.setState({
            lastCheckedBills: lastCheckedBills
        });
    }

    categoryDescriptionIsEdited(categoryAsDisplayed, associatedReferenceCategory) {
        if (this.props.chamberCode === 'H' && categoryAsDisplayed.CategoryCode === "SENBRC" || categoryAsDisplayed.CategoryCode === "HOUBRC") {
            return !(
                ["House Bill in Conference", "Senate Bill in Conference",
                    "House Bills in Conference", "Senate Bills in Conference",
                    "House Joint Resolution in Conference", "Senate Joint Resolution in Conference",
                    "House Joint Resolutions in Conference", "Senate Joint Resolutions in Conference",
                    "House Bill and Joint Resolution in Conference", "Senate Bill and Joint Resolution in Conference",
                    "House Bills and Joint Resolution in Conference", "Senate Bills and Joint Resolution in Conference",
                    "House Bill and Joint Resolutions in Conference", "Senate Bill and Joint Resolutions in Conference",
                    "House Bills and Joint Resolutions in Conference", "Senate Bills and Joint Resolutions in Conference",
                ].includes(categoryAsDisplayed.Description))
        } else {
            if (associatedReferenceCategory && associatedReferenceCategory.Description !== categoryAsDisplayed.Description && associatedReferenceCategory.PluralDescription !== categoryAsDisplayed.Description) {
                return true;
            } else {
                return false;
            }
        }
    }

    setDescription(organizedBills, categoryIndex) {
        let updatedOrganizedBills = [...organizedBills];

        if (categoryIndex) {
            //if it's been edited, leave it at that
            const associatedReferenceCategory = this.props.calendarCategories.find(x => x.CalendarCategoryTypeID === updatedOrganizedBills.filter(c => !c.DeletionDate)[categoryIndex].CalendarCategoryTypeID);
            if (this.categoryDescriptionIsEdited(updatedOrganizedBills.filter(c => !c.DeletionDate)[categoryIndex], associatedReferenceCategory)) {
                //leave it alone
            } else {
                //otherwise, set description based on singular/plural reference description
                if (this.props.chamberCode === 'H' && updatedOrganizedBills.filter(c => !c.DeletionDate)[categoryIndex].CategoryCode === "SENBRC" || updatedOrganizedBills.filter(c => !c.DeletionDate)[categoryIndex].CategoryCode === "HOUBRC") {
                    updatedOrganizedBills.filter(c => !c.DeletionDate)[categoryIndex].Description = this.setBillsInConferenceCategoryDescription(updatedOrganizedBills.filter(c => !c.DeletionDate)[categoryIndex]);
                } else {
                    if (associatedReferenceCategory) {
                        if (updatedOrganizedBills.filter(c => !c.DeletionDate)[categoryIndex].Agendas.filter(x => x.IsActive).length > 1) {
                            updatedOrganizedBills.filter(c => !c.DeletionDate)[categoryIndex].Description = this.props.calendarCategories.find(x => x.CalendarCategoryTypeID === updatedOrganizedBills.filter(c => !c.DeletionDate)[categoryIndex].CalendarCategoryTypeID).PluralDescription;
                        } else {
                            updatedOrganizedBills.filter(c => !c.DeletionDate)[categoryIndex].Description = this.props.calendarCategories.find(x => x.CalendarCategoryTypeID === updatedOrganizedBills.filter(c => !c.DeletionDate)[categoryIndex].CalendarCategoryTypeID).Description;
                        }
                    }
                }
            }
        } else {
            //iterate through all
            updatedOrganizedBills.forEach((cat, index) => {
                //if it's been edited, leave it at that
                const associatedReferenceCategory = this.props.calendarCategories.find(x => x.CalendarCategoryTypeID === updatedOrganizedBills[index].CalendarCategoryTypeID)
                if (this.categoryDescriptionIsEdited(cat, associatedReferenceCategory)) {
                    return;
                }

                //otherwise, set description based on singular/plural reference description
                if (this.props.chamberCode === 'H' && cat.CategoryCode === "SENBRC" || cat.CategoryCode === "HOUBRC") {
                    updatedOrganizedBills[index].Description = this.setBillsInConferenceCategoryDescription(cat);
                } else {
                    if (associatedReferenceCategory) { //calendarCategories prop only contains same chamber categories, so could cause an NRE if iterating over an opposite-chamber category
                        if (updatedOrganizedBills[index].Agendas.filter(x => x.IsActive).length > 1) {
                            updatedOrganizedBills[index].Description = associatedReferenceCategory.PluralDescription;
                        } else {
                            updatedOrganizedBills[index].Description = associatedReferenceCategory.Description;
                        }
                    }
                }
            })
        }

        return updatedOrganizedBills;
    }

    setBillsInConferenceCategoryDescription(category) {
        let existingDescription = category.Description;
        let newTypeAndPluralityLanguage;
        let newDescription;

        //get all legislations in this category
        const categoryLegislations = category.Agendas;
        if (categoryLegislations) {
            //filter to only the active legislations
            const activeCategoryLegislations = categoryLegislations.filter(cl => cl.IsActive);
            if (!activeCategoryLegislations.length) {
                //not any active legislations - should only say 'bill'
                newTypeAndPluralityLanguage = 'Bill';
            } else {
                const numResolutions = activeCategoryLegislations.filter(acl => acl.LegislationNumber[1] !== "B").length;
                const numBills = activeCategoryLegislations.filter(acl => acl.LegislationNumber[1] === "B").length;
                if (numResolutions > 0 && numBills > 0) {
                    //At least one bill and at least one resolution
                    newTypeAndPluralityLanguage = (numBills > 1 ? 'Bills' : 'Bill') + ' and Joint ';
                    newTypeAndPluralityLanguage += numResolutions > 1 ? 'Resolutions' : 'Resolution';
                } else if (numResolutions > 0 && numBills === 0) {
                    //at least one resolution and no bills
                    newTypeAndPluralityLanguage = numResolutions > 1 ? 'Joint Resolutions' : 'Joint Resolution';
                } else if (numResolutions === 0 && numBills > 0) {
                    //at least one bill and no resolutions
                    newTypeAndPluralityLanguage = numBills > 1 ? 'Bills' : 'Bill';
                }
            }
            newDescription = existingDescription.replace(/\b(Bill and Joint Resolution|Bills and Joint Resolution|Bills and Joint Resolutions|Bill and Joint Resolutions|Bill|Bills|Joint Resolution|Joint Resolutions)\b/, newTypeAndPluralityLanguage);
        }

        return newDescription;
    }

    handleAgendaSort(agendas, categoryTypeId) {
        let unrankedAgendas = [];
        let rankedAgendas = [];
        let inactiveAgendas = [];
        let rankings = [];

        if (agendas) {
            agendas.forEach((agenda) => {
                if (!agenda.Ranking && agenda.IsActive) {
                    unrankedAgendas.push(agenda);
                } else if (agenda.IsActive) {
                    rankedAgendas.push(agenda);
                    rankings.push(agenda.Ranking);
                } else {
                    inactiveAgendas.push(agenda)
                }
            })

            rankings.sort((a, b) => a - b);

            let gaps = [];
            for (let i = 1; i < rankings.length; i++) {
                gaps.push(rankings[i] - rankings[i - 1]);
            }

            console.log(agendas, categoryTypeId)


            if (NUMERIC_SORT_CATEGORIES.includes(categoryTypeId)) {
                rankedAgendas.sort((a, b) => sortAgendasNumerically(a, b, this.props.chamberCode));
            } else {
                rankedAgendas.sort((a, b) => sortAgendas(a, b, this.props.chamberCode));
            }
            if (NUMERIC_SORT_CATEGORIES.includes(categoryTypeId)) {
                unrankedAgendas.sort((a, b) => sortAgendasNumerically(a, b, this.props.chamberCode));
            } else {
                unrankedAgendas.sort((a, b) => sortAgendas(a, b, this.props.chamberCode));
            }
            if (NUMERIC_SORT_CATEGORIES.includes(categoryTypeId)) {
                inactiveAgendas.sort((a, b) => sortInactiveAgendasNumerically(a, b, this.props.chamberCode));
            } else {
                inactiveAgendas.sort((a, b) => sortInactiveAgendas(a, b, this.props.chamberCode));
            }

            //If there are multiple ranks with a gap larger than 1 place all unranked agendas between them
            if (new Set(rankings).size > 1 && Math.max(...gaps) > 1) {
                let insertPoint = gaps.findIndex(x => x === Math.max(...gaps)) + 1

                for (let i = 0; i < unrankedAgendas.length; i++) {
                    rankedAgendas.splice(insertPoint, 0, unrankedAgendas[i]);
                    insertPoint++;
                }

                return rankedAgendas.concat(inactiveAgendas);
            } else {
                //If the largest rank is greated than 50 put all unranked agendas above the ranked agendas, otherwise put them all at the bottom
                if (Math.max(...rankings) >= 50) {
                    return unrankedAgendas.concat(rankedAgendas).concat(inactiveAgendas);
                } else {
                    return rankedAgendas.concat(unrankedAgendas).concat(inactiveAgendas);

                }
            }
        } else {
            return agendas;
        }
    }

    toggleBill(categoryIndex, itemIndex) {
        let organizedBills = [...this.props.organizedBills];
        let agenda = organizedBills.filter(c => !c.DeletionDate)[categoryIndex].Agendas[itemIndex]
        agenda.IsActive = !agenda.IsActive;
        agenda.checked = false;
        if (agenda.pending === false) {
            delete agenda.pending;
        }

        organizedBills = this.setDescription(organizedBills, categoryIndex);
        organizedBills[categoryIndex].Agendas = this.handleAgendaSort(organizedBills.filter(c => !c.DeletionDate)[categoryIndex].Agendas, organizedBills.filter(c => !c.DeletionDate)[categoryIndex].CalendarCategoryTypeID)

        //check for this bill being a duplicate in other categories
        const isDuplicate = organizedBills.filter(cc => cc.CalendarCategoryTypeID !== organizedBills.filter(c => !c.DeletionDate)[categoryIndex].CalendarCategoryTypeID && cc.Agendas.find(a => a.LegislationNumber === agenda.LegislationNumber))
        if (isDuplicate.length) {
            isDuplicate.forEach(duplicateCategory => {
                duplicateCategory.Agendas.filter(a => a.LegislationNumber === agenda.LegislationNumber).forEach(duplicateAgenda => {
                    duplicateAgenda.IsActive = false;
                    duplicateAgenda.Ranking = null;
                })
                const duplicateCategoryIndex = organizedBills.findIndex(cc => cc.CalendarCategoryTypeID === duplicateCategory.CalendarCategoryTypeID);
                organizedBills = this.setDescription(organizedBills, duplicateCategoryIndex);
                const response = this.handleAgendaSort(organizedBills[duplicateCategoryIndex].Agendas, organizedBills[duplicateCategoryIndex].CalendarCategoryTypeID)
                organizedBills[duplicateCategoryIndex].Agendas = response;
            })
        }

        this.props.updateAgenda(organizedBills, true);
    }

    toggleBillBulk(activate, categoryIndex) {
        let organizedBills = [...this.props.organizedBills];

        const legNumbersToBeIncluded = activate ? organizedBills.filter(c => !c.DeletionDate)[categoryIndex].Agendas.filter(a => a.checked).flatMap(a => a.LegislationNumber) : [];
        organizedBills.filter(c => !c.DeletionDate)[categoryIndex].Agendas.forEach(leg => {
            if (leg.checked) {
                leg.IsActive = activate;
            }

            if (leg.pending === false) {
                delete leg.pending;
            }

            leg.checked = false;
        });

        organizedBills = this.setDescription(organizedBills, categoryIndex);
        organizedBills[categoryIndex].Agendas = this.handleAgendaSort(organizedBills.filter(c => !c.DeletionDate)[categoryIndex].Agendas, organizedBills.filter(c => !c.DeletionDate)[categoryIndex].CalendarCategoryTypeID)

        //check for this bill being a duplicate in other categories
        const isDuplicate = organizedBills.filter(cc => cc.CalendarCategoryTypeID !== organizedBills.filter(c => !c.DeletionDate)[categoryIndex].CalendarCategoryTypeID && cc.Agendas.find(a => legNumbersToBeIncluded.includes(a.LegislationNumber)))
        if (isDuplicate.length) {
            isDuplicate.forEach(duplicateCategory => {
                duplicateCategory.Agendas.filter(a => legNumbersToBeIncluded.includes(a.LegislationNumber)).forEach(duplicateAgenda => {
                    duplicateAgenda.IsActive = false;
                    duplicateAgenda.Ranking = null;
                })
                const duplicateCategoryIndex = organizedBills.findIndex(cc => cc.CalendarCategoryTypeID === duplicateCategory.CalendarCategoryTypeID);
                organizedBills = this.setDescription(organizedBills, duplicateCategoryIndex);
                organizedBills[duplicateCategoryIndex].Agendas.sort((a, b) => NUMERIC_SORT_CATEGORIES.includes(organizedBills[duplicateCategoryIndex].CalendarCategoryTypeID) ? sortAgendasNumerically(a, b, this.props.chamberCode) : sortAgendas(a, b, this.props.chamberCode));
            })
        }

        this.props.updateAgenda(organizedBills, true);
    }

    moveTo(topOrBottom, categoryIndex) {
        let organizedBills = [...this.props.organizedBills];

        organizedBills.filter(c => !c.DeletionDate)[categoryIndex].Agendas.forEach(leg => {
            if (leg.checked && leg.IsActive) {
                leg.Ranking = topOrBottom === "top" ? 1 : 99
            }
        });

        organizedBills.filter(c => !c.DeletionDate)[categoryIndex].Agendas.filter(a => a.checked).forEach(agenda => {
            agenda.checked = false;
        });

        organizedBills = this.setDescription(organizedBills, categoryIndex);

        const result = this.handleAgendaSort(organizedBills.filter(c => !c.DeletionDate)[categoryIndex].Agendas, organizedBills.filter(c => !c.DeletionDate)[categoryIndex].CalendarCategoryTypeID)
        organizedBills.filter(c => !c.DeletionDate)[categoryIndex].Agendas = result;
        this.props.updateAgenda(organizedBills, true);
    }

    switchCategory(categoryIndex) {
        let organizedBills = [...this.props.organizedBills];

        //uncheck the ones that are already in this category so that they don't get resequenced
        organizedBills.filter(c => !c.DeletionDate)[categoryIndex].Agendas.forEach(a => a.checked = false);

        let categoriesWithCheckedBills = organizedBills.filter(c => !c.DeletionDate && c.IsLegislationCategory && c.Agendas.find(a => a.checked));
        categoriesWithCheckedBills.forEach(cat => {
            cat.Agendas.filter(a => a.checked).forEach(checkedAgenda => {
                checkedAgenda.Ranking = 0; //will get set later in toggleBillBulk
                if (organizedBills.filter(c => !c.DeletionDate)[categoryIndex].CalendarCategoryID) {
                    checkedAgenda.CalendarCategoryID = organizedBills.filter(c => !c.DeletionDate)[categoryIndex].CalendarCategoryID;
                } else {
                    delete checkedAgenda.CalendarCategoryID;
                }
                organizedBills.filter(c => !c.DeletionDate)[categoryIndex].Agendas.push(checkedAgenda);
                const existingCategoryAgendaIndex = cat.Agendas.findIndex(a => a.LegislationNumber === checkedAgenda.LegislationNumber);
                if (existingCategoryAgendaIndex > -1) { //remove the bill from the old category; always should be found
                    cat.Agendas.splice(existingCategoryAgendaIndex, 1);
                }
            })
        })

        this.toggleBillBulk(true, categoryIndex);
    }

    showAgendaItems(categoryIndex, agendaIndex) {
        let organizedBills = [...this.props.organizedBills].filter(c => !c.DeletionDate);
        organizedBills.filter(c => !c.DeletionDate)[categoryIndex].Agendas[agendaIndex].showAgendaItems = !organizedBills[categoryIndex].Agendas[agendaIndex].showAgendaItems;
        this.props.updateAgenda(organizedBills);
    }

    toggleEdit(categoryIndex, agendaIndex) {
        let categories = [...this.props.organizedBills];
        //This key is used to tell the DOM whether to display an input or a span
        categories.filter(c => !c.DeletionDate)[categoryIndex].Agendas[agendaIndex].isEditing = !categories.filter(c => !c.DeletionDate)[categoryIndex].Agendas[agendaIndex].isEditing;
        //Create a key called newDescription that will hold the text that  the user types in
        categories.filter(c => !c.DeletionDate)[categoryIndex].Agendas[agendaIndex].newDescription = categories.filter(c => !c.DeletionDate)[categoryIndex].Agendas[agendaIndex].Description;
        this.props.updateAgenda(categories);
    }

    handleChange(categoryIndex, agendaIndex, value, title) {
        let categories = [...this.props.organizedBills];
        if (title) {
            categories.filter(c => !c.DeletionDate)[categoryIndex].Agendas[agendaIndex].LegislationTitle = value;
        } else {
            categories.filter(c => !c.DeletionDate)[categoryIndex].Agendas[agendaIndex].newDescription = value;
        }

        this.props.updateAgenda(categories);
    }

    saveChange(categoryIndex, agendaIndex) {
        //Turn input back into span
        let categories = [...this.props.organizedBills];
        categories.filter(c => !c.DeletionDate)[categoryIndex].Agendas[agendaIndex].isEditing = false;
        //Set description to the text that the user has typed in. If new description is null then set it to the current description
        categories.filter(c => !c.DeletionDate)[categoryIndex].Agendas[agendaIndex].Description = categories.filter(c => !c.DeletionDate)[categoryIndex].Agendas[agendaIndex].newDescription;
        this.props.updateAgenda(categories, true);
    }

    expandTitle(categoryIndex, agendaIndex) {
        let categories = [...this.props.organizedBills];
        categories.filter(c => !c.DeletionDate)[categoryIndex].Agendas[agendaIndex].expandTitle = !categories.filter(c => !c.DeletionDate)[categoryIndex].Agendas[agendaIndex].expandTitle ? true : false;

        this.props.updateAgenda(categories, this.props.pendingSave);
    }

    onDragEnd(result, categoryIndex) {

    }

    toggleAgendaItemEditor(agendaIndex, categoryIndex) {
        if (!categoryIndex || ([...this.props.organizedBills].filter(c => !c.DeletionDate).length && [...this.props.organizedBills].filter(c => !c.DeletionDate)[categoryIndex])) {
            this.setState({
                showAgendaItemEditor: agendaIndex,
                showAgendaItemEditorCategoryIndex: Number.isInteger(categoryIndex) ? categoryIndex : -1
            });
        }
    }

    getCategoryBills(category) {
        const associatedCategory = this.props.calendarCategories.find(c => c.CalendarCategoryTypeID === category.CalendarCategoryTypeID);
        if (associatedCategory) {
            return this.props.billList.filter(b => (b.ChamberCode === associatedCategory.LegislationChamberCode || !associatedCategory.LegislationChamberCode) && (b.LegislationTypeCode === associatedCategory.LegislationTypeCode || !associatedCategory.LegislationTypeCode))
        } else { return this.props.billList }
    }

    toggleShowAmendments(categoryIndex) {
        let organizedBills = [...this.props.organizedBills];
        organizedBills.filter(c => !c.DeletionDate)[categoryIndex].showAmendments = !organizedBills[categoryIndex].showAmendments;
        organizedBills.filter(c => !c.DeletionDate)[categoryIndex].Agendas.forEach(agenda => {
            agenda.AgendaItems.forEach(item => {
                if (item.LDNumber) {
                    item.IsActive = organizedBills[categoryIndex].showAmendments;
                }
            })
        });
        this.props.updateAgenda(organizedBills);
    }

    setRanking(categoryIndex, agendaIndex, ranking) {
        let organizedBills = [...this.props.organizedBills];
        if (parseInt(ranking)) {
            organizedBills[categoryIndex].Agendas[agendaIndex].Ranking = parseInt(ranking);
        } else if (ranking === "") {
            organizedBills[categoryIndex].Agendas[agendaIndex].Ranking = "";
        }
        this.props.updateAgenda(organizedBills);
    }

    changeCommitteeDropdown(value) {
        this.props.changeCommitteeDropdown(value, () => {
            let organizedBills = [...this.props.organizedBills];
            organizedBills = this.setDescription(organizedBills);
            this.props.updateAgenda(organizedBills, true);
        });
    }

    changeDateDropdown(value) {
        this.props.changeDateDropdown(value, () => {
            let organizedBills = [...this.props.organizedBills];
            organizedBills = this.setDescription(organizedBills);
            this.props.updateAgenda(organizedBills, true);
        });
    }

    addBill(categoryIndex) {
        this.props.addBill(categoryIndex, () => {
            let organizedBills = [...this.props.organizedBills];
            organizedBills = this.setDescription(organizedBills);
            this.props.updateAgenda(organizedBills, true);
        });
    }

    toggleEditCategoryDescription(categoryIndex, save) {
        const categories = [...this.props.organizedBills];
        let category = categories.filter(c => !c.DeletionDate)[categoryIndex];
        category.editing = !category.editing;
        if (save) {
            category.Description = category.tempDescription;
        } else {
            category.tempDescription = this.categoryDescription(category);
        }
        this.props.updateAgenda(categories, save);
    }

    handleDescriptionChange(categoryIndex, val) {
        let organizedBills = [...this.props.organizedBills];
        organizedBills[categoryIndex].tempDescription = val;
        this.props.updateAgenda(organizedBills);
    }

    categoryDescription = category => {
        //if it's been edited, leave it at that
        const associatedReferenceCategory = this.props.calendarCategories.find(x => x.CalendarCategoryTypeID === category.CalendarCategoryTypeID);
        if (associatedReferenceCategory && associatedReferenceCategory.Description !== category.Description && associatedReferenceCategory.PluralDescription !== category.Description) {
            return category.Description;
        }

        //otherwise, use singular or plural reference description
        if (this.props.chamberCode === 'H' && category.CategoryCode === "HOUBRC" || category.CategoryCode === "SENBRC") {
            return category.Description;
        } else if (category.Agendas.filter(a => a.IsActive).length > 1 && category.PluralDescription) {
            return category.PluralDescription;
        } else {
            return category.Description;
        }
    }

    selectAllCommending(categoryIndex) {
        let organizedBills = [...this.props.organizedBills];
        const opposite = !organizedBills.filter(c => !c.DeletionDate)[categoryIndex].selectAllCommending;
        organizedBills[categoryIndex].selectAllCommending = opposite;
        organizedBills[categoryIndex].Agendas.forEach(x => { if (x.LegislationClassID === 3) { x.checked = opposite } })
        this.props.updateAgenda(organizedBills);
    }

    selectAllMemorial(categoryIndex) {
        let organizedBills = [...this.props.organizedBills];
        const opposite = !organizedBills.filter(c => !c.DeletionDate)[categoryIndex].selectAllMemorial;
        organizedBills[categoryIndex].selectAllMemorial = opposite
        organizedBills[categoryIndex].Agendas.forEach(x => { if (x.LegislationClassID === 2) { x.checked = opposite } })
        this.props.updateAgenda(organizedBills);
    }

    render() {
        //Filter out the categories that have already been added to the calendar
        const calendarCategoryOptions = this.props.calendarCategories.filter(category => {
            if (this.props.organizedBills.findIndex(cat => !cat.DeletionDate && cat.CalendarCategoryTypeID === category.CalendarCategoryTypeID) < 0) {
                return category;
            }
        });
        // The button will read 'Add' if the bill is not already part of a category. The button will read 'Move' if it is already part of a category.
        const billButtonText = index => {
            let billExists = false;
            if (this.props.selectedBill[index]) {
                this.props.organizedBills.some(category => {
                    if (category.Agendas.findIndex(bill => bill.LegislationID === this.props.selectedBill[index].LegislationID) >= 0) {
                        billExists = true;
                        return true;
                    }
                });
                if (billExists) {
                    return ("Move");
                }
            }
            return ("Add");
        }

        return (
            <div className="drag-container">
                {this.state.showAgendaItemEditor !== -1 &&
                    <AgendaItemsComponent
                        toggleAgendaItemEditor={this.toggleAgendaItemEditor}
                        agenda={this.props.organizedBills.filter(c => !c.DeletionDate)[this.state.showAgendaItemEditorCategoryIndex].Agendas[this.state.showAgendaItemEditor]}
                        categoryIndex={this.state.showAgendaItemEditorCategoryIndex}
                        agendaIndex={this.state.showAgendaItemEditor}
                        updateAgendaItems={this.props.updateAgendaItems}
                    />
                }
                <div className="inner-grid two">
                    <React.Fragment>
                        <span>Use these two selectors to view candidate bills reported out of committee.</span>
                        <div />
                        <div className="inner-grid two">
                            <div>
                                <label>Committee Reported Out</label>
                                <Select
                                    classNamePrefix="grid-selector-dropdown"
                                    value={this.props.selectedCommittee}
                                    onChange={this.changeCommitteeDropdown}
                                    options={this.props.committeeList.sort((a, b) => a.label > b.label)}
                                    styles={{ menu: provided => ({ ...provided, zIndex: this.props.organizedBills.length + 1 }) }}
                                    isSearchable
                                    isClearable
                                />
                            </div>
                            <div>
                                <label>Date Reported Out</label>
                                <Select
                                    classNamePrefix="grid-selector-dropdown"
                                    value={this.props.selectedDate}
                                    onChange={this.changeDateDropdown}
                                    options={this.props.dateList.sort((a, b) => a.label > b.label)}
                                    styles={{ menu: provided => ({ ...provided, zIndex: this.props.organizedBills.length + 1 }) }}
                                    isSearchable
                                    isClearable
                                />
                            </div>
                        </div>
                    </React.Fragment>
                    <div className="inner-grid eight-and-one">
                        <div>
                            <label>Category</label>
                            <Select
                                classNamePrefix="grid-selector-dropdown"
                                options={calendarCategoryOptions}
                                value={this.props.selectedCategory}
                                onChange={this.props.changeCategoryDropdown}
                                styles={{ menu: provided => ({ ...provided, textAlign: 'right', whiteSpace: 'nowrap', right: '0', width: 'auto', zIndex: this.props.organizedBills.length + 1 }) }}
                            />
                        </div>
                        <button disabled={!this.props.selectedCategory} type="button" className="button primary" style={{ marginTop: "24px", height: "36px" }} onClick={this.props.addCategory}>Add Category</button>
                    </div>
                </div>
                <div style={{ display: 'flex', gap: '25px' }}>
                    <button className="button-link" onClick={() => this.props.toggleHideAllCategories(this.props.hideCategories.filter(c => c).length !== this.props.organizedBills.length)}>{this.props.hideCategories.filter(c => c).length !== this.props.organizedBills.length ? "Collapse All Categories" : "Expand All Categories"}</button>
                    <button className="button-link" onClick={() => this.props.toggleHideAllNonIncludedBills()}>{this.props.hideAllNonIncludedBills ? "Show All Legislation" : "Hide Non-Included Legislation"}</button>
                </div>
                <div style={{ marginTop: 15 }}>
                    <div className="bill-grid communications-category-grid calendar-category-grid">
                        <div className="bill-grid-header">
                            <span>Seq.</span>
                        </div>
                        <div className="bill-grid-header">
                            <span></span>
                        </div>
                        <div className="bill-grid-header">
                            <span>Bill #</span>
                        </div>
                        <div className="bill-grid-header">
                            <span>Committee/Date Reported</span>
                        </div>
                        <div className="bill-grid-header">
                            <span>{this.props.showTitles ? "Title" : "Caption"}</span>
                        </div>
                        <div className="bill-grid-header">
                            <span>Status</span>
                        </div>
                        <div className="bill-grid-header">
                            <span>Action</span>
                        </div>
                    </div>
                    {this.props.organizedBills.filter(c => !c.DeletionDate).map((category, categoryIndex) =>
                        <form key={categoryIndex}>
                            <div style={{ position: 'sticky', top: '0px', zIndex: this.props.organizedBills.filter(c => !c.DeletionDate).length - categoryIndex }}>
                                <div className={`calendar-category-grid calendar-category-grid-header${category.Agendas && category.Agendas.length ? '' : ' deletable'}`}>
                                    <div>
                                        <button onClick={() => this.props.toggleHideCategory(categoryIndex)} type="button" className={`${this.props.hideCategories[categoryIndex] ? "arrow-down" : "arrow-up"}`}></button>
                                    </div>
                                    <div>
                                        <input
                                            type="checkbox"
                                            className="checkbox"
                                            checked={category.selectAll || ''}
                                            onChange={() => this.toggleSelectAll(categoryIndex)}
                                        />
                                    </div>
                                    <div>
                                        <span>
                                            {category.editing ?
                                                <React.Fragment>
                                                    <textarea
                                                        className={`category-description-${categoryIndex}`}
                                                        style={{ width: '90%', fontSize: '0.9em' }}
                                                        type="text"
                                                        onChange={(e) => this.handleDescriptionChange(categoryIndex, e.target.value)}
                                                        value={category.tempDescription}
                                                    />
                                                    <a className="icon save" style={{ marginLeft: '7px' }} onClick={() => this.toggleEditCategoryDescription(categoryIndex, true)} />
                                                    <a className="icon delete" style={{ marginLeft: '7px' }} onClick={() => this.toggleEditCategoryDescription(categoryIndex)} />
                                                </React.Fragment>
                                                :
                                                <React.Fragment>
                                                    <b>{category.DisplayType ? `${this.categoryDescription(category)} - ${category.CategoryType} ` : this.categoryDescription(category)}</b>
                                                    <a className="icon edit" style={{ marginLeft: '7px' }} onClick={() => this.toggleEditCategoryDescription(categoryIndex)} />
                                                </React.Fragment>
                                            }
                                        </span>
                                        {category.CalendarCategoryTypeID === 38 &&
                                            <div style={{ display: "inline-flex" }}>
                                                <button type="button" class="button small-button" style={{ marginRight: "10px" }} onClick={() => this.selectAllCommending(categoryIndex)}>Select All Commending</button>
                                                <button type="button" class="button small-button" onClick={() => this.selectAllMemorial(categoryIndex)}>Select All Memorial</button>
                                            </div>
                                        }
                                    </div>
                                    <div>
                                        {!category.Agendas || !category.Agendas.length ? <button onClick={() => this.props.deleteCategory(category)} className="icon delete" type="button" />
                                            :
                                            this.props.userRoles.find(x => x === "SenateCalendarEditor") && [98, 102].includes(category.CalendarCategoryTypeID) &&
                                            <React.Fragment>
                                                <label style={{ margin: "0 5px 0 0" }}>Show w/ Amendments</label>
                                                <div className="bill-grid-actions">
                                                    <div onClick={() => this.toggleShowAmendments(categoryIndex)} className="toggle-switch">
                                                        <input id="sr-status-change" checked={category.showAmendments} type="checkbox" />
                                                        <span className="slider"></span>
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        }
                                    </div>
                                    <div className="flex-row" style={{ gap: '10px' }}>
                                        {
                                            category.IsLegislationCategory && this.props.organizedBills.find(cat => !cat.DeletionDate && cat.CalendarCategoryTypeID !== category.CalendarCategoryTypeID && cat.IsLegislationCategory && cat.Agendas.find(agenda => agenda.checked)) ?
                                                <button type="button" className="button small-button" onClick={() => this.switchCategory(categoryIndex)}>Move to Category</button>
                                                : !category.IsLegislationCategory && category.Agendas.length && category.Agendas.find(a => a.checked) &&
                                                <React.Fragment>
                                                    <div>
                                                        <button type="button" className="button small-button" onClick={() => this.toggleBillBulk(false, categoryIndex)}>Exclude Selected</button>
                                                    </div>
                                                    <div>
                                                        <button type="button" className="button small-button" onClick={() => this.toggleBillBulk(true, categoryIndex)}>Include Selected</button>
                                                    </div>
                                                </React.Fragment>
                                        }
                                    </div>
                                    <div>
                                        {!this.props.pendingSave && <button type="button" className="button small-button" onClick={() => this.props.toggleCalendarPreview(categoryIndex)} >Preview</button>}
                                    </div>
                                    <div>
                                        {!this.props.pendingSave && category.CalendarCategoryTypeID != 131 && <button type="button" className="button small-button" onClick={() => this.props.togglePortal(this.props.showPortal, category)}>PDF Preview </button>}
                                    </div>
                                </div>
                                {!this.props.hideCategories[categoryIndex] && category.IsLegislationCategory &&
                                    <div className="bill-grid communications-category-grid calendar-category-grid">
                                        <div></div>
                                        <div></div>
                                        <div>
                                            <LargeListSelect
                                                className="grid-selector-dropdown"
                                                classNamePrefix="grid-selector-dropdown"
                                                options={this.getCategoryBills(category)}
                                                onChange={(value) => this.props.changeBillDropdown(categoryIndex, value)}
                                                value={this.props.selectedBill[categoryIndex]}
                                                filterOption={(bill, inputValue) => {
                                                    if (category.Agendas.findIndex(agenda => agenda.LegislationID === bill.value) < 0) {
                                                        return bill.label.toLowerCase().includes(inputValue.toLowerCase());
                                                    } else { return false; }
                                                }}
                                            />
                                        </div>
                                        <div className="center">
                                            <button
                                                className="button"
                                                type="button"
                                                disabled={!this.props.selectedBill[categoryIndex]}
                                                onClick={() => this.addBill(categoryIndex)}>
                                                {billButtonText(categoryIndex)}
                                            </button>
                                        </div>
                                        <div className="flex-row four space-around" style={{ display: 'flex', alignItems: 'center' }}>
                                            {category.Agendas.length && category.Agendas.find(a => a.checked) ?
                                                <React.Fragment>
                                                    <div>
                                                        <span>Checked Legislation:</span>
                                                    </div>
                                                    <div>
                                                        <button type="button" className="button small-button" onClick={() => this.toggleBillBulk(false, categoryIndex)}>Exclude</button>
                                                    </div>
                                                    <div>
                                                        <button type="button" className="button small-button" onClick={() => this.toggleBillBulk(true, categoryIndex)}>Include</button>
                                                    </div>
                                                    <div>
                                                        <button type="button" className="button small-button" onClick={() => this.moveTo('top', categoryIndex)}>Move to Top</button>
                                                    </div>
                                                    <div>
                                                        <button type="button" className="button small-button" onClick={() => this.moveTo('bottom', categoryIndex)}>Move to Bottom</button>
                                                    </div>
                                                </React.Fragment>
                                                : null
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                            {!this.props.hideCategories[categoryIndex] &&
                                <div>
                                    {category.Agendas.map((item, itemIndex) => {
                                        if (this.props.hideAllNonIncludedBills && !item.IsActive) { return; }
                                        const showNewIndicator = category.CategoryCode != "Order" && category.CalendarCategoryID && !item.AgendaID;
                                        return (
                                            <React.Fragment key={`${item.AgendaID}-${itemIndex}`}>
                                                {isNotHidden(item, category, this.props.committeeList) ?
                                                    <div className="bill-grid communications-category-grid calendar-category-grid"
                                                        style={getItemStyle(
                                                            false,
                                                            false,
                                                            item.IsActive
                                                        )}
                                                    >
                                                        <div className="dlas-forms" style={{ padding: "5px" }}><input type="input" value={item.Ranking ? item.Ranking : ""} onChange={(e) => this.setRanking(categoryIndex, itemIndex, e.target.value)} /></div>
                                                        <div>
                                                            <input
                                                                type="checkbox"
                                                                className="checkbox"
                                                                checked={item.checked || ''}
                                                                onChange={e => this.toggleRow(categoryIndex, itemIndex, e)} />
                                                        </div>
                                                        <div className="new-container">
                                                            {this.props.organizedBills.find(c => c.CalendarCategoryTypeID !== category.CalendarCategoryTypeID && c.Agendas.find(a => a.LegislationNumber === item.LegislationNumber)) &&
                                                                <React.Fragment>
                                                                    <i data-tip="This legislation exists in multiple categories." className={`icon notification calendar-authoring${showNewIndicator ? ' new' : ''}`} />
                                                                </React.Fragment>
                                                            }
                                                            <Link to={`/bill-details/${this.props.sessionCode}/${item.LegislationNumber}`} target="_blank" rel="noreferrer" style={!item.IsActive ? { color: 'inherit' } : {}}> {item.LegislationNumber}</Link>
                                                            {showNewIndicator && <span className='corner-new'>NEW</span>}
                                                        </div>
                                                        <div>
                                                            <span>{this.props.fullCommitteeList.find(x => x.CommitteeID === item.CommitteeID) ? this.props.fullCommitteeList.find(x => x.CommitteeID === item.CommitteeID).Abbreviation + " - " + moment(item.CandidateDate).format("MM/DD/YYYY") : category.CategoryCode != "Order" && item.CandidateDate && moment(item.CandidateDate).format("MM/DD/YYYY")}</span>
                                                        </div>
                                                        <div>
                                                            <div className="flex-row" style={{ display: 'flex' }}>
                                                                <div className="flex-row flex-vertical-center" style={{ width: "100%", display: "inline-flex" }}>
                                                                    {item.isEditing
                                                                        ? category.Description !== "Order of Business"
                                                                            ? <Editor
                                                                                init={{
                                                                                    inline: true,
                                                                                    toolbar: false,
                                                                                    menubar: false
                                                                                }}
                                                                                onEditorChange={(content) => this.handleChange(categoryIndex, itemIndex, content, item.LegislationTitle != null)}
                                                                                value={item.LegislationTitle ? item.LegislationTitle : item.newDescription}
                                                                            />
                                                                            : <textarea
                                                                                onChange={(e) => this.handleChange(categoryIndex, itemIndex, e.target.value, item.LegislationTitle != null)}
                                                                                value={item.LegislationTitle ? item.LegislationTitle : item.newDescription}
                                                                            />
                                                                        :
                                                                        <React.Fragment>
                                                                            <span className={this.props.organizedBills && this.props.organizedBills.filter(c => !c.DeletionDate)[categoryIndex].Agendas[itemIndex].expandTitle ? "calendar-leg-title-wrapper expanded" : "calendar-leg-title-wrapper"}>{item.Description === "Call to Order" ? "Call to Order - " + this.props.calendarTime : renderHTML(!this.props.showTitles && item.LegislationDescription ? item.LegislationDescription : !this.props.showTitles && item.Description ? item.Description : this.props.showTitles && item.LegislationTitle ? item.LegislationTitle : item.Description ? item.Description : "")}</span>
                                                                            {!this.props.showTitles && item.LegislationDescription && item.LegislationDescription.length > 75
                                                                                ? <a onClick={() => this.expandTitle(categoryIndex, itemIndex)} style={{ cursor: "pointer" }}>...</a>
                                                                                : !this.props.showTitles && item.Description && item.Description.length > 75
                                                                                    ? <a onClick={() => this.expandTitle(categoryIndex, itemIndex)} style={{ cursor: "pointer" }}>...</a>
                                                                                    : this.props.showTitles && item.LegislationTitle && item.LegislationTitle.length > 75
                                                                                        ? <a onClick={() => this.expandTitle(categoryIndex, itemIndex)} style={{ cursor: "pointer" }}>...</a>
                                                                                        : item.Description && item.Description.length > 75
                                                                                            ? <a onClick={() => this.expandTitle(categoryIndex, itemIndex)} style={{ cursor: "pointer" }}>...</a>
                                                                                            : null}
                                                                        </React.Fragment>
                                                                    }
                                                                </div>
                                                                <React.Fragment>
                                                                    <div className="bill-grid-actions">
                                                                        {item.isEditing
                                                                            ?
                                                                            <div className="bill-grid-actions" style={{ display: "inline-flex" }}>
                                                                                <button onClick={() => this.saveChange(categoryIndex, itemIndex)} type="button" className="icon save float-right"></button>
                                                                                <button onClick={() => this.toggleEdit(categoryIndex, itemIndex)} type="button" className="icon delete float-right"></button>
                                                                            </div>
                                                                            :
                                                                            <div className="bill-grid-actions">
                                                                                {/*Allow edit if it is not the call to order item, the item IsActive, and we are viewing titles OR it is the invocation, pledge, or reading of the journal*/}
                                                                                {item.Description !== "Call to Order" && item.IsActive && (this.props.showTitles || [2, 3, 4, 6, 7, 8].includes(item.CalendarCategoryTemplateID)) &&
                                                                                    <button onClick={() => this.toggleEdit(categoryIndex, itemIndex)} type="button" className="icon edit float-right"></button>
                                                                                }
                                                                            </div>
                                                                        }
                                                                        {category.CategoryCode !== "Order" && <button onClick={() => this.showAgendaItems(categoryIndex, itemIndex)} className={item.showAgendaItems ? 'arrow-up' : 'arrow-down'} type="button"></button>}
                                                                    </div>
                                                                </React.Fragment>
                                                            </div>
                                                            {item.showAgendaItems &&
                                                                <div>
                                                                    <button type="button" onClick={() => this.toggleAgendaItemEditor(itemIndex, categoryIndex)} className="button-link">Edit Notes</button>
                                                                    {/* If an item has an LdNumber it's assumed to be an amendment */}
                                                                    {item.AgendaItems.filter(item => item.Description && item.IsActive && (!item.LDNumber ? true : category.showAmendments || ![98, 102].includes(category.CalendarCategoryTypeID) ? true : false)).map((item, itemIndex) =>
                                                                        <div key={itemIndex} className="programmatic-notes">
                                                                            {renderHTML(item.DraftText || item.CalendarDescription || item.Description)}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            }
                                                        </div>
                                                        <div>
                                                            <span className={item.IsActive ? "message-success" : ""}>{item.IsActive ? 'Included' : 'Not Included'}</span>
                                                        </div>
                                                        <div className="bill-grid-actions">
                                                            <div onClick={() => this.toggleBill(categoryIndex, itemIndex)} className="toggle-switch">
                                                                <input id="sr-status-change" checked={item.IsActive} type="checkbox" />
                                                                <span className="slider"></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    null
                                                }
                                            </React.Fragment>
                                        )
                                    }
                                    )}
                                </div>
                            }
                        </form>
                    )}
                </div>
            </div>
        );
    }
}

export default BillsGrid;