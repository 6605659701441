import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { committeeActionCreators } from '../../../stores/lis-committee-store';
import { memberActionCreators } from '../../../stores/lis-members-store';
import 'tinymce/themes/silver/theme';
import 'tinymce/icons/default'
import 'tinymce/plugins/paste/plugin';
import 'tinymce/plugins/link/plugin';
import 'tinymce/plugins/save/plugin';
import 'tinymce/plugins/lists/plugin';
import 'tinymce/plugins/code/plugin';
import 'tinymce/plugins/preview/plugin';
import 'tinymce/plugins/searchreplace/plugin';
import 'tinymce/plugins/pagebreak/plugin';
import 'tinymce/plugins/table/plugin';
import '../../../stylesheets/tinymce/oxide/skin.min.css';
import '../../../stylesheets/tinymce/legislation_text_stylesheet.scss';
import '../../../stylesheets/tinymce/custom.scss';
import { Editor } from '@tinymce/tinymce-react';
import '../../../lis-shared/tinymce/lineNumberPlugin';
import '../../../lis-shared/tinymce/copyHeadStylePlugin';
import '../../../lis-shared/tinymce/rejectedButtonPlugin';
import '../../../lis-shared/tinymce/agreedButtonPlugin';
import '../../../lis-shared/tinymce/createAmendmentPlugin';
import '../../../lis-shared/tinymce/rightMarginPlugin';
import tinymce from 'tinymce';

class ReviewSection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            members: [],
            committees: [],
            selectedBillNumberIsLoaded: true,
            selectedSponsorIsLoaded: true,
            selectedMemberIsLoaded: true,
            selectedCommitteeIsLoaded: true,
            showLineNumbers: true,
            showTooltip: false,
            currentFormats: ""
        }

        this.toggleShowLineNumbers = this.toggleShowLineNumbers.bind(this);
        this.toggleTooltip = this.toggleTooltip.bind(this);
        this.setFormat = this.setFormat.bind(this);
    }

    loadMembers(sessionId) {
        this.setState({ memberIsLoaded: false })
        this.props.actions.getMemberList(`sessionID=${sessionId}`)
            .then(() => {
                const members = this.props.members.memberList
                    .filter(m => m.ListDisplayName)
                    .map(m => ({ value: (m.ChamberCode === "H" ? "DEL. " : "SEN. ") + m.PatronDisplayName, text: m.ListDisplayName }));
                this.setState({
                    members: members
                }, () => {
                    if (this.props.membersCallback) {
                        this.props.membersCallback(this.props.members.memberList)
                    }
                });
            }).finally(() => {
                this.setState({
                    memberIsLoaded: true
                })
            });
    }

    loadCommittees(sessionId) {
        this.setState({ committeIsLoaded: false })
        this.props.actions.getCommitteeList(`sessionID=${sessionId}` + "&includeSubCommittees=true")
            .then(() => {
                const committees = this.props.committee.committeeList.filter(x => !x.ParentCommitteeID).map(c => ({ value: c.Name, text: c.Name }));
                this.setState({
                    committees: committees
                }, () => {
                    if (this.props.committeesCallback) {
                        this.props.committeesCallback(this.props.committee.committeeList)
                    }
                });
            }).finally(() => {
                this.setState({
                    committeeIsLoaded: true
                })
            });
    }

    handleEditorChange(content) {
        this.props.handleEditorChange(content);
        //Hack to set the cursor position to the right of the first character entered
        //This is because the editor sets the cursor position to index 0 after automatically applying the default style to the first paragraph

        //We replace the content of the editor with itself after entering the first character and automatically applying the default format in the parent component
        //This places the cursor on the right hand side of the character
        //The booleans are used to prevent an infinite loop of handleEditorChange calls

        //If the editor had no content after the last keydown then mark the editor as having been empty
        if (content.length === 0 && !this.props.wasEmpty) {
            this.props.setWasEmpty(true);
            //Else if the content length is 24 (Paragraph tag with the section class apllied containing one character)
            //And the editor was previously empty
            //And there has been no replacement yet
            //Then mark that the edior was not empty after the last keydown, that the text has been replaced, and then execute the replacement
        } else if (content.length == 24 && this.props.wasEmpty && !this.props.replaced) {
            this.props.setWasEmpty(false, () => {
                this.props.setReplaced(true, () => {
                    tinymce.activeEditor.execCommand('selectAll');
                    tinymce.activeEditor.execCommand('mceReplaceContent', true, content);
                });
            })
            //Else this was any other kind of keydown event, mark that the editor was not empty, and that no replacement took place
        } else {
            this.props.setWasEmpty(false, () => {
                this.props.setReplaced(false);
            })
        }
    }

    toggleShowLineNumbers() {
        this.setState({
            showLineNumbers: !this.state.showLineNumbers
        }, () => {
            if (this.state.showLineNumbers) {
                localStorage.setItem("showLineNumbers", true)
                tinymce.activeEditor.execCommand("mceInsertNewLine");
                tinymce.activeEditor.execCommand("Undo");
            } else {
                localStorage.setItem("showLineNumbers", false)
                tinymce.activeEditor.execCommand("mceInsertNewLine");
                tinymce.activeEditor.execCommand("Undo");
            }
        })
    }

    toggleTooltip() {
        this.setState({
            showTooltip: !this.state.showTooltip
        })
    }

    setFormat() {
        this.setState({
            currentFormats: tinymce.activeEditor.contentWindow.getSelection().focusNode.parentElement.classList[0] //We can always assume classList[0] because we only allow one format at a time
        })
    }

    componentDidMount() {
        //Default line numbers off for very large bills to prevent crashing on initial laod
        if (this.props.editorContent.length < 80000 && !this.props.versionAuthoring) {
            localStorage.setItem("showLineNumbers", true);
        } else {
            this.setState({
                showLineNumbers: false
            }, () => {
                localStorage.setItem("showLineNumbers", false);
            })
        }

        if (this.props.sessionId) {
            this.loadCommittees(this.props.sessionId);
            this.loadMembers(this.props.sessionId);
        } else {
            this.setState({
                committeeIsLoaded: true,
                memberIsLoaded: true
            })
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.sessionId !== this.props.sessionId) {
            this.loadCommittees(this.props.sessionId);
            this.loadMembers(this.props.sessionId);
        }

        //below is annoying and seems useless but is needed in order to get the add amendment wizard to auto-populate based on user's sponsor/member/cmte choices
        if (prevProps.billNumber !== this.props.billNumber || prevProps.selectedSponsor !== this.props.selectedSponsor || prevProps.selectedMember !== this.props.selectedMember || prevProps.selectedCommittee !== this.props.selectedCommittee) {
            this.setState({ selectedBillNumberIsLoaded: false, selectedSponsorIsLoaded: false, selectedMemberIsLoaded: false, selectedCommitteeIsLoaded: false, }, () => {
                this.setState({ selectedBillNumberIsLoaded: true, selectedSponsorIsLoaded: true, selectedMemberIsLoaded: true, selectedCommitteeIsLoaded: true })
            })
        }
    }

    render() {
        if (!this.state.memberIsLoaded || !this.state.committeeIsLoaded || !this.state.selectedBillNumberIsLoaded || !this.state.selectedSponsorIsLoaded || !this.state.selectedMemberIsLoaded || !this.state.selectedCommitteeIsLoaded) {
            return <div className="spinner center-spinner"></div>;
        }

        const setup = (editor) => {
            editor.ui.registry.addToggleButton('bracketbutton', {
                text: "[ ]",
                tooltip: "Insert Brackets",
                onAction: function () {
                    editor.insertContent('<span class="bracket">[</span> ');
                    let bookmark = editor.selection.getBookmark(2);
                    editor.insertContent('<span class="bracket">]</span>')
                    editor.selection.moveToBookmark(bookmark);
                }
            });
            editor.ui.registry.addToggleButton('lBracketbutton', {
                text: "[",
                tooltip: "Insert Left Bracket",
                onAction: function () {
                    editor.insertContent('<span class="bracket">[</span>');
                }
            });
            editor.ui.registry.addToggleButton('rBracketbutton', {
                text: "]",
                tooltip: "Insert Right Bracket",
                onAction: function () {
                    editor.insertContent('<span class="bracket">]</span>');
                }
            });
            editor.ui.registry.addToggleButton('sectionButton', {
                text: "§",
                tooltip: "Insert Section Symbol",
                onAction: function () {
                    editor.insertContent('§');
                }
            });
            editor.on("keydown", (e) => {
                this.setFormat();
            });
            editor.on("click", (e) => {
                this.setFormat();
            })
        }

        return (
            <React.Fragment key={this.props.reviewKey}>
                <div className='inner-grid eight-and-one'>
                    <div />
                    <div style={{ display: "inline-flex", marginBottom: "20px", marginTop: this.props.location === "/amendment-management" ? "13px" : "8px" }}>
                        <a onClick={(e) => { e.stopPropagation(e); this.toggleTooltip() }} className="button info" style={{ margin: "10px 10px 0 0" }}>Info</a>
                        <button type="button" className="button primary" onClick={() => this.toggleShowLineNumbers()}>{(this.state.showLineNumbers ? "Hide" : "Show") + " Line Numbers"}</button>
                        {this.state.showTooltip &&
                            <div className="info-box editor-tooltip">
                                <p>Hiding line numbers will improve the response time of the editor. You may turn them back on at any point during editing. It may take some time to turn on line numbers for exceptionally large bills.</p>
                            </div>
                        }
                    </div>
                </div>
                <div style={{ width: "100%", height: "40px" }}>
                    {this.state.currentFormats &&
                        <span style={{ float: "right" }}>{"Format: " + this.state.currentFormats}</span>
                    }
                </div>
                <Editor
                    value={this.props.editorContent}
                    init={{
                        setup: setup,
                        browser_spellcheck: true,
                        height: this.props.height || 500,
                        plugins: 'paste save lists preview searchreplace pagebreak code linenumbers table copyheadstyle rightmargin' + (this.props.allowAmendmentActions ? ' rejectedbutton agreedbutton createamendment' : ''),
                        toolbar: 'undo redo | styleselect | fontsizeselect | bold italic strikethrough underline | alignleft aligncenter alignright | bracketButton lBracketbutton rBracketbutton | sectionButton | ' + (this.props.allowAmendmentActions && !this.props.overrideRemoveAgreeRejectButtons ? ' | rejectedbuttons agreedbutton' : '') + (this.props.allowAmendmentActions && !this.props.overrideRemoveCreateAmendmentButton ? ' | createamendment' : ''),
                        contextmenu: this.props.allowAmendmentActions ? 'rejectedbuttonscontext agreedbuttoncontext' : '',
                        skin: false,
                        paste_as_text: this.props.pasteAsText || this.props.pasteAsText === undefined,
                        members: this.state.members,
                        committees: this.state.committees,
                        valid_children: '+body[style]',
                        content_css: false,
                        content_style: 'table { border-collapse: collapse; border: 1px solid #FFFFFF; }' + 'td { padding: 12px 0px; }',
                        userChamber: this.props.userChamber,
                        billNumber: this.props.billNumber,
                        sponsor: this.props.selectedSponsor ? this.props.selectedSponsor.value : '',
                        member: this.props.selectedMember && this.state.members.find(m => m.text === this.props.selectedMember.value.ListDisplayName) ? this.state.members.find(m => m.text === this.props.selectedMember.value.ListDisplayName).value : '',
                        committee: this.props.selectedCommittee && this.state.committees.find(c => c.text === this.props.selectedCommittee.value.Name) ? this.state.committees.find(c => c.text === this.props.selectedCommittee.value.Name).value : '',
                        amendmentOrigin: this.props.selectedMember && this.state.members.find(m => m.text === this.props.selectedMember.value.ListDisplayName) ? this.props.selectedMember.value.ChamberCode : this.props.selectedCommittee && this.state.committees.find(c => c.text === this.props.selectedCommittee.value.Name) ? this.props.selectedCommittee.value.ChamberCode : this.props.amendmentOrigin ? this.props.amendmentOrigin : '',
                        allowGovernorAmendmentSponsor: this.props.allowGovernorAmendmentSponsor,
                        paste_word_valid_elements: 'p,table,tbody,td,tr,s,u,b,sup,sub',
                        paste_convert_word_fake_lists: false, //doesn't convert indented <p> tags to <ul>,<ol> etc...
                        paste_enable_default_filters: false, //doesn't add the TinyMCE classes
                        paste_data_images: true,
                        paste_preprocess: function (plugin, args) { //removes the Word "curved quotes"
                            args.content = args.content.replace('&ldquo;', '"').replace('&rdquo;', '"').replace('"', '"').replace('"', '"');
                        },
                        table_default_attributes: {
                            border: '0'
                        },
                        formats: {
                            section: {
                                block: 'p', attributes: { class: 'section' }
                            },
                            ldtitle: {
                                block: 'p', attributes: { class: 'ldtitle' }
                            },
                            enactstatement: {
                                block: 'p', attributes: { class: 'enactstm' }
                            },
                            enactclause: {
                                block: 'p', attributes: { class: 'enactcls' }
                            },
                            billnumber: {
                                block: 'p', attributes: { class: 'billno' }
                            },
                            catchline: {
                                block: 'p', attributes: { class: 'catchln' }
                            },
                            rejected: {
                                block: 'p', attributes: { class: 'rejected' }
                            },
                            i10: {
                                block: 'p', attributes: { class: 'i10' }
                            },
                            i0: {
                                block: 'p', attributes: { class: 'i0' }
                            },
                            i15: {
                                block: 'p', attributes: { class: 'i15' }
                            },
                            i20: {
                                block: 'p', attributes: { class: 'i20' }
                            },
                            customcenter: {
                                block: 'p', attributes: { class: 'center' }
                            },
                            strikethrough: {
                                inline: 's'
                            },
                            end: {
                                block: 'p', attributes: { class: 'end' }
                            },
                            textind: {
                                block: 'p', attributes: { class: 'textind' }
                            },
                            textbl: {
                                block: 'p', attributes: { class: 'textbl' }
                            },
                            textbi: {
                                block: 'p', attributes: { class: 'textbi' }
                            },
                            textasis: {
                                block: 'p', attributes: { class: 'textasis' }
                            },
                            table: {
                                block: 'p', attributes: { class: 'table' }
                            },
                            testc: {
                                block: 'p', attributes: { class: 'testc' }
                            },
                            tablec: {
                                block: 'p', attributes: { class: 'tablec' }
                            },
                            tblhdn: {
                                block: 'p', attributes: { class: 'tblhdn' }
                            },
                            tbllft: {
                                block: 'p', attributes: { class: 'tbllft' }
                            },
                            tblctr: {
                                block: 'p', attributes: { class: 'tblctr' }
                            },
                            tblrt: {
                                block: 'p', attributes: { class: 'tblrt' }
                            },
                            txtctr: {
                                block: 'p', attributes: { class: 'txtctr' }
                            },
                            drafter: {
                                block: 'p', attributes: { class: 'drafter' }
                            },
                            billblank: {
                                block: 'p', attributes: { class: 'billblank' }
                            },
                            pro: {
                                block: 'p', attributes: { class: 'pro' }
                            },
                            sumtext: {
                                block: 'p', attributes: { class: 'sumtext' }
                            },
                            lddata: {
                                block: 'p', attributes: { class: 'lddata' }
                            },
                            ld: {
                                block: 'p', attributes: { class: 'ld' }
                            },
                            patctr: {
                                block: 'p', attributes: { class: 'patctr' }
                            },
                            patalt: {
                                block: 'p', attributes: { class: 'patalt' }
                            },
                            textrt: {
                                block: 'p', attributes: { class: 'textrt' }
                            },
                            testhdrchar: {
                                block: 'p', attributes: { class: 'testhdrchar' }
                            },
                            texthdr: {
                                block: 'p', attributes: { class: 'texthdr' }
                            },
                            new: {
                                block: 'p', classes: 'new'
                            }
                        },
                        style_formats: [
                            { title: 'Section', format: 'section' },
                            { title: 'LD Title', format: 'ldtitle' },
                            { title: 'Enact Statement', format: 'enactstatement' },
                            { title: 'Enact Clause', format: 'enactclause' },
                            { title: 'Bill Number', format: 'billnumber' },
                            { title: 'Catchline', format: 'catchline' },
                            { title: 'Rejected', format: 'rejected' },
                            { title: 'i10', format: 'i10' },
                            { title: 'i0', format: 'i0' },
                            { title: 'i15', format: 'i15' },
                            { title: 'i20', format: 'i20' },
                            { title: 'Center', format: 'customcenter' },
                            { title: "End", format: "end" },
                            { title: "Textind", format: "textind" },
                            { title: "Textbl", format: "textbl" },
                            { title: "Textbi", format: "textbi" },
                            { title: "Textasis", format: "textasis" },
                            { title: "Table", format: "table" },
                            { title: "Testc", format: "testc" },
                            { title: "Tablec", format: "tablec" },
                            { title: "Tblhdn", format: "tblhdn" },
                            { title: "Tbllft", format: "tbllft" },
                            { title: "Tblctr", format: "tblctr" },
                            { title: "Tblrt", format: "tblrt" },
                            { title: "Txtctr", format: "txtctr" },
                            { title: "Drafter", format: "drafter" },
                            { title: "Billblank", format: "billblank" },
                            { title: "Pro", format: "pro" },
                            { title: "Sumtext", format: "sumtext" },
                            { title: "Lddata", format: "lddata" },
                            { title: "Ld", format: "ld" },
                            { title: "Patctr", format: "patctr" },
                            { title: "Patalt", format: "patalt" },
                            { title: "Textrt", format: "textrt" },
                            { title: "Testhdrchar", format: "testhdrchar" },
                            { title: "Texthdr", format: "texthdr" },
                            { title: "New", format: "new" }
                        ],
                    }}
                    onEditorChange={(e) => this.handleEditorChange(e)}
                />
            </React.Fragment>
        );
    }
}


export default connect(
    (state) => {
        const { committee, members } = state;
        return {
            committee,
            members
        }
    },
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, committeeActionCreators, memberActionCreators), dispatch)
        }
    }
)(ReviewSection)